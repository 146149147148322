import { Avatar, CircularProgress } from '@mui/material'
import { map as mapAwait } from 'awaiting'
import { getDatabase, ref as dbRef, onValue, set } from 'firebase/database'
import { v4 as uuidv4 } from 'uuid'
import {
  getStorage,
  uploadBytes,
  getDownloadURL,
  ref as stRef
} from 'firebase/storage'
import { getDroppedOrSelectedFiles } from 'html5-file-selector'

import React, { useEffect, useState } from 'react'
import Dropzone from 'react-dropzone-uploader'
import { useNavigate } from 'react-router-dom'

import { ImageContainer } from '../styles/experience'
import { Message, UploadContainer } from '../styles/upload'
import { Container, ProfileWrapper, TextContainer } from '../styles/userprofile'

const UserDashBoard = () => {
  const [loading, setLoading] = useState(false)
  const [curUser, setCurUser] = useState({})
  const [id, setId] = useState({})
  // const [, setFiles] = useState([])

  const navigate = useNavigate()

  const fetchCurrentUser = uid => {
    const db = getDatabase()

    onValue(
      dbRef(db, `users/${uid}`),
      snapshot => {
        setCurUser(snapshot.val())
        setLoading(false)
      },
      { onlyOnce: true }
    )
  }
  console.log({ curUser })

  useEffect(() => {
    const uid = window.localStorage.getItem('user')
    setId(uid)
    setLoading(true)
    if (curUser === null) {
      return navigate('/')
    }

    fetchCurrentUser(uid)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fileParams = ({ meta }) => {
    return { url: 'https://httpbin.org/post' }
  }
  const createFileUploads = async (files, uploader, id) => {
    try {
      if (files < 1) {
        window.alert(
          'Please add an image and click sumbit to add atleast one image'
        )
        return
      }
      const res = await mapAwait(files, files.length, uploader(id))
      return res
    } catch (e) {
      throw new Error(e)
    }
  }

  const uploadImage = id => async file => {
    const storage = getStorage()
    const imageId = uuidv4()
    const storageRef = stRef(storage, `users/${id}/tripDocs/${imageId}.jpg`)
    try {
      const metadata = {
        contentType: 'image/jpeg'
      }
      const data = file.file
      const snapshot = await uploadBytes(storageRef, data, metadata)
      const url = await getDownloadURL(snapshot.ref)
      console.log(url)
      return url
    } catch (e) {
      throw new Error(e)
    }
  }

  const onSubmit = async (files, allFiles) => {
    const db = getDatabase()
    const documents = await createFileUploads(files, uploadImage, id)
    await set(dbRef(db, 'users/' + id + '/travelData'), {
      documents
    })
    window.alert('Documents uploaded sucessfully')
  }
  const getFilesFromEvent = e => {
    return new Promise(resolve => {
      getDroppedOrSelectedFiles(e).then(chosenFiles => {
        resolve(chosenFiles.map(f => f.fileObject))
      })
    })
  }

  const selectFileInput = ({ accept, onFiles, files, getFilesFromEvent }) => {
    const textMsg =
      files.length > 0
        ? 'Drag or Click to upload more images'
        : 'Drag files or click to add photo of documents'
    return (
      <>
        <Message> {loading && 'UPLOADING IMAGES'}</Message>
        <UploadContainer>
          <Message> {textMsg}</Message>
          <input
            style={{ display: 'none' }}
            type='file'
            accept={accept}
            multiple
            onChange={e => {
              getFilesFromEvent(e).then(chosenFiles => {
                onFiles(chosenFiles)
              })
            }}
          />
        </UploadContainer>
      </>
    )
  }

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '90vh'
        }}
      >
        <CircularProgress />
      </div>
    )
  }

  return (
    <Container>
      <div
        style={{
          color: 'white',
          textAlign: 'center',
          textTransform: 'uppercase',
          fontWeight: 'bold',
          fontFamily: 'Fira Sans Condensed',
          fontSize: '20px'
        }}
      >
        Profile
      </div>
      <ProfileWrapper>
        <Avatar
          sx={{ m: 1, bgcolor: 'secondary.main', width: 70, height: 70 }}
        />
        <TextContainer>
          <span style={{ textTransform: 'capitalize', marginBottom: '5px' }}>
            {`${curUser.firstName} ${curUser.lastName}`}
          </span>
          <br />
          <span>{curUser.email}</span>
        </TextContainer>

        <ImageContainer>
          <Dropzone
            onSubmit={onSubmit}
            InputComponent={selectFileInput}
            getFilesFromEvent={getFilesFromEvent}
            getUploadParams={fileParams}
            accept='image/*'
            inputContent='Drop A File'
            styles={{
              dropzone: {
                width: '40vw',
                height: '40vh',
                marginTop: '5vh',
                color: 'white'
              },
              dropzoneActive: { borderColor: 'green' }
            }}
          />
        </ImageContainer>
        {/* {renderLoader()} */}
      </ProfileWrapper>
    </Container>
  )
}

export default UserDashBoard
