import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React from 'react'

const Selector = ({ selected, onChanged, text }) => {
  const changeHandler = event => {
    onChanged(event.target.value)
  }

  return (
    <>
      <FormControl
        fullWidth
        margin='normal'
        sx={{ fontFamily: 'Fira Sans Condensed' }}
      >
        <InputLabel>{text}</InputLabel>
        <Select
          fullWidth
          label='Select Yes Or No'
          value={selected}
          onChange={changeHandler}
          required
        >
          <MenuItem value='Yes'>Yes</MenuItem>
          <MenuItem value='No'>No</MenuItem>
        </Select>
      </FormControl>
    </>
  )
}

export default Selector
