import React, { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress'

const ProtectedRoute = ({ component: Component }) => {
  const [loading, setLoading] = useState(true)
  const [loggedIn, setLoggedIn] = useState(false)
  const user = window.localStorage.getItem('user')
  useEffect(() => {
    if (user === 'Rqpa4oEh9uYsokamsGAvGzvgnM13') {
      setLoggedIn(true)
      setLoading(false)
    }
    setLoading(false)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  if (loading) return <CircularProgress />
  if (loggedIn) {
    return <Component />
  }
  return <Navigate to='/' />
}

export default ProtectedRoute
