import * as React from 'react';
import {useState, useEffect} from 'react';
import {AnimatePresence} from 'framer-motion';
import {Helmet} from 'react-helmet';
import {wrap} from 'popmotion';
import {getImages} from './image-data';
import {Photo, PhotoContainer, Next, Prev} from '../styles/photos';
import {useScreenfull} from 'use-screenfull';
import './Photos.css';
import axios from 'axios';
import {CircularProgress} from '@material-ui/core';

const variants = {
  enter: (direction) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
};

/**
 * Experimenting with distilling swipe offset and velocity into a single variable, so the
 * less distance a user has swiped, the more velocity they need to register as a swipe.
 * Should accomodate longer swipes and short flicks without having binary checks on
 * just distance thresholds and velocity > 0.
 */
const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};

export const Photos = () => {
  const [[page, direction], setPage] = useState([0, 0]);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingDeleteBtn, setLoadingDeleteBtn] = useState(false);
  const user = window.localStorage.getItem('user');

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const data = await getImages();
        setImages(data);
        setLoading(false);
        console.log({data});
      } catch (err) {
        window.alert('An error occured');
        setLoading(false);
      }
    };
    fetchImages();
  }, []);
  const {requestFullscreen} = useScreenfull();

  // We only have 3 images, but we paginate them absolutely (ie 1, 2, 3, 4, 5...) and
  // then wrap that within 0-2 to find our image ID in the array below. By passing an
  // absolute page index as the `motion` component's `key` prop, `AnimatePresence` will
  // detect it as an entirely new image. So you can infinitely paginate as few as 1 images.
  const imageIndex = wrap(0, images.length, page);

  const handleClick = async () => {
    const url = images[imageIndex].Url;
    const createdOn = images[imageIndex].Created_On;
    const imagePath = url.replace(
      'https://ettah-service-image-prod.s3.amazonaws.com/',
      ''
    );
    const S3KEY = encodeURIComponent(imagePath);
    const SORTKEY = encodeURIComponent(createdOn);
    console.log({S3KEY, SORTKEY})
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': process.env.REACT_APP_FILE_API_KEY,
        },
      };
      setLoadingDeleteBtn(true);
      await axios.post(
        `${process.env.REACT_APP_FILE_SERVICE_URL}/prod/delete-image/${S3KEY}/Image/${SORTKEY}`, {},
        config
      );

      const updatedImages = images.filter((image) => {
        return image.Url !== url;
      });
      console.log(images);
      setImages(updatedImages);
      setLoadingDeleteBtn(false);
    } catch (error) {
      console.log(error);
    }
  };

  const paginate = (newDirection) => {
    setPage([page + newDirection, newDirection]);
  };
  if (loading) {
    return <PhotoContainer>LOADING</PhotoContainer>;
  }
  if (images.length < 1) {
    return <PhotoContainer>NO IMAGES TO DISPLAY</PhotoContainer>;
  }

  const renderLoader = () => {
    if (loadingDeleteBtn) {
      return <CircularProgress />;
    }
    return (
      <button
        onClick={handleClick}
        style={{
          cursor: 'pointer',
        }}
      >
        DELETE
      </button>
    );
  };

  return (
    <>
      <div
        style={{
          color: 'white',
          textAlign: 'center',
          textTransform: 'uppercase',
          fontWeight: 'bold',
          fontFamily: 'Fira Sans Condensed',
          fontSize: '20px',
        }}
      >
        Photos
      </div>

      <Helmet>
        <title>Photos</title>
        <meta name='Photos' content='All Photos' />
      </Helmet>
      <AnimatePresence initial={false} custom={direction}>
        <PhotoContainer>
          <Photo
            onClick={requestFullscreen}
            key={page}
            src={images[imageIndex].Url}
            custom={direction}
            variants={variants}
            initial='enter'
            animate='center'
            exit='exit'
            transition={{
              x: {type: 'spring', stiffness: 300, damping: 30},
              opacity: {duration: 0.2},
            }}
            drag='x'
            dragConstraints={{left: 0, right: 0}}
            dragElastic={1}
            onDragEnd={(e, {offset, velocity}) => {
              const swipe = swipePower(offset.x, velocity.x);

              if (swipe < -swipeConfidenceThreshold) {
                paginate(1);
              } else if (swipe > swipeConfidenceThreshold) {
                paginate(-1);
              }
            }}
          />
        </PhotoContainer>
      </AnimatePresence>
      <Next onClick={() => paginate(1)}>‣</Next>
      <Prev onClick={() => paginate(-1)}>‣</Prev>
      {user === 'Rqpa4oEh9uYsokamsGAvGzvgnM13' && renderLoader()}
    </>
  );
};
