import styled from 'styled-components'

export const ImageContainer = styled.div`
    /* width: 50vw;
    height: 50vh; */
    /* float: left; */
  @media (max-width: '768px') {
    float: none;
    width: 100vw;
  }
`
export const TextContainer = styled.div`
    /* width: 50vw;
    height: 50vh; */
  @media (max-width: '768px') {
    display: block;
    width: 100vw;
  }
`
