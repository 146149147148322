import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
  CloseMenu,
  HamMenu,
  MobileLinks,
  MobileLinksUl,
  StyledHamContainer,
  StyledMobileOptionsContainer,
  StyledMobileOptionsContainerUnderlay,
  StyledUnderlay
} from '../styles/mobileView'
import {
  Container,
  LogoContainer,
  LinksContainer,
  LinksUL,
  LinksL,
  Logo
} from '../styles/navbar'
import { useAuth } from '../hooks/useAuth'

// import Logo from './Logo'

const links = ['PHOTOS', 'POLICY', 'EXPERIENCES']

const navigateToHome = navigate => e => {
  e.preventDefault()
  navigate('/')
}

export const Navbar = () => {
  const [navMenuState, toggleNavMenu] = useState(false)
  const navigate = useNavigate()
  const {
    state: { user },
    actions: { logoutUser }
  } = useAuth()
  const handleLogout = () => logoutUser()
  const renderLogin = () => {
    if (user.user.uid) {
      return (
        <LinksL
          onClick={handleLogout}
          whileHover={{
            opacity: 0.5,
            transition: { duration: 0.2 }
          }}
        >
          LOGOUT
        </LinksL>
      )
    }
    return (
      <Link to='/login'>
        <LinksL
          whileHover={{
            opacity: 0.5,
            transition: { duration: 0.2 }
          }}
        >
          LOGIN
        </LinksL>
      </Link>
    )
  }
  const renderProfile = () => {
    if (user.user.uid) {
      return (
        <Link to='/profile'>
          <LinksL
            whileHover={{
              opacity: 0.5,
              transition: { duration: 0.2 }
            }}
          >
            MY PROFILE
          </LinksL>
        </Link>
      )
    }
  }
  const renderLoginMobile = () => {
    if (user.user.uid) {
      return (
        <MobileLinks
          onClick={handleLogout}
          whileHover={{
            opacity: 0.5,
            transition: { duration: 0.2 }
          }}
        >
          LOGOUT
        </MobileLinks>
      )
    }
    return (
      <Link to='/login'>
        <MobileLinks
          whileHover={{
            opacity: 0.5,
            transition: { duration: 0.2 }
          }}
        >
          LOGIN
        </MobileLinks>
      </Link>
    )
  }

  const renderMobileMenu = () => {
    return (
      <StyledMobileOptionsContainerUnderlay
        onClick={() => toggleNavMenu(!navMenuState)}
      >
        <StyledMobileOptionsContainer
          initial={{ opacity: 0.2 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.2 }}
        >
          {links.map((link, i) => (
            <Link key={i} to={`/${link.toLowerCase()}`}>
              <MobileLinks
                whileHover={{
                  opacity: 0.5,
                  transition: { duration: 0.2 }
                }}
                key={i}
              >
                {link}
              </MobileLinks>
            </Link>
          ))}
          {renderLoginMobile()}
        </StyledMobileOptionsContainer>
      </StyledMobileOptionsContainerUnderlay>
    )
  }

  const renderLinks = () => {
    return links.map((link, i) => (
      <Link key={i} to={`/${link.toLowerCase()}`}>
        <LinksL
          whileHover={{
            opacity: 0.5,
            transition: { duration: 0.2 }
          }}
          key={i}
        >
          {link}
        </LinksL>
      </Link>
    ))
  }

  return (
    <Container>
      {navMenuState && (
        <StyledUnderlay onClick={() => toggleNavMenu(!navMenuState)} />
      )}
      {navMenuState && <MobileLinksUl>{renderMobileMenu()}</MobileLinksUl>}
      <LogoContainer onClick={navigateToHome(navigate)}>
        <Logo src='https://firebasestorage.googleapis.com/v0/b/anlotravel-2e6dc.appspot.com/o/frontpage%2Fanlo_logo-3_1.svg?alt=media&token=db656d1f-ec86-4b91-a1c9-a793cca88d44' />
      </LogoContainer>
      <LinksContainer>
        <LinksUL>
          {renderLogin()}
          {renderLinks()}
          {renderProfile()}
        </LinksUL>
      </LinksContainer>

      <StyledHamContainer onClick={() => toggleNavMenu(!navMenuState)}>
        {navMenuState ? <CloseMenu /> : <HamMenu />}
      </StyledHamContainer>
    </Container>
  )
}
