import { combineReducers } from 'redux'
import { userLoginSlice } from './auth'
import { creatExperienceSlice } from './experience'

const rootReducer = combineReducers({
  user: userLoginSlice.reducer,
  experience: creatExperienceSlice.reducer
})

export default rootReducer
