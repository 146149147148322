import * as React from 'react'
import { useState, useEffect } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { Helmet } from 'react-helmet'
import { useNavigate, useParams } from 'react-router-dom'
import { getDatabase, ref as dbRef, onValue } from 'firebase/database'
import Linkify from 'react-linkify'
import { multiply } from 'ramda'
import { isNotNilOrEmpty } from '@meltwater/phi'
import { v4 as uuidv4 } from 'uuid'
import axios from 'axios'
import { AnimatePresence } from 'framer-motion'
import { wrap } from 'popmotion'
import moment from 'moment'
import { Photo2, PhotoContainer2 } from '../styles/photos'
import { useScreenfull } from 'use-screenfull'
import {
  TripMetaContainer,
  Location,
  Heading,
  Price,
  Pay,
  Pay2,
  Date,
  Description,
  DesContainer
} from '../styles/trip'
import { AlertDialog } from './Dialog'
import { useAuth } from '../hooks/useAuth'

const variants = {
  enter: direction => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0
    }
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1
  },
  exit: direction => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0
    }
  }
}

/**
 * Experimenting with distilling swipe offset and velocity into a single variable, so the
 * less distance a user has swiped, the more velocity they need to register as a swipe.
 * Should accomodate longer swipes and short flicks without having binary checks on
 * just distance thresholds and velocity > 0.
 */
const swipeConfidenceThreshold = 10000
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity
}

const config = {
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': process.env.REACT_APP_PAY_KEY
  }
}

export const Trip = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const {
    state: { user }
  } = useAuth()
  const [[page, direction], setPage] = useState([0, 0])
  const [images, setImages] = useState([])
  const [loading, setLoading] = useState(true)
  const [payLoading, setPayLoading] = useState(false)
  const [openD, setOpenD] = useState(false)
  const [experience, setExperience] = useState({})
  const fetchExperiences = () => {
    const db = getDatabase()
    onValue(
      dbRef(db, `experiences/${id}`),
      snapshot => {
        setExperience(snapshot.val())
        setImages(snapshot.val().images)
        setLoading(false)
      },
      { onlyOnce: true }
    )
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    fetchExperiences()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const { requestFullscreen } = useScreenfull()

  // const data = {
  //   email: user.user.email,
  //   description: `Secure ${experience.location} trip spot`
  // }
  const date = moment(experience.date).format('ddd MMM DD YYYY')
  const endDate = moment(experience.endDate).format('ddd MMM DD YYYY')
  const handlePayment = async e => {
    const data = {
      line_items: [
        {
          name: experience.location,
          description: `Secure ${experience.location} trip full payment`,
          amount: multiply(experience.price, 100),
          currency: 'usd',
          quantity: 1
        }
      ],
      metadata: {
        userId: user.user.uid,
        orderId: uuidv4()
      }
    }
    console.log(data)
    try {
      e.preventDefault()
      setPayLoading(true)
      const res = await axios.post(
        `${process.env.REACT_APP_PAY_SERVICE_URL}/prod/create-payment`,
        data,
        config
      )
      window.location.assign(res.data.body.url)
    } catch (err) {
      setPayLoading(false)
      window.alert('An error occured please try again')
    }
  }
  const handleInstallPayment = async e => {
    const data = {
      line_items: [
        {
          name: experience.location,
          description: `Secure ${experience.location} trip deposit`,
          amount: multiply(multiply(experience.price, 100), 0.15),
          currency: 'usd',
          quantity: 1
        }
      ],
      metadata: {
        userId: user.user.uid,
        orderId: uuidv4()
      }
    }
    try {
      e.preventDefault()
      setPayLoading(true)
      const res = await axios.post(
        `${process.env.REACT_APP_PAY_SERVICE_URL}/prod/create-payment`,
        data,
        config
      )
      window.location.assign(res.data.body.url)
    } catch (err) {
      setPayLoading(false)
      window.alert('An error occured please try again')
    }
  }

  const imageIndex = wrap(0, images.length, page)
  console.log(images)

  const paginate = newDirection => {
    setPage([page + newDirection, newDirection])
  }
  const handleToggle = e => {
    e.preventDefault()
    setOpenD(true)
  }
  console.log(user)
  const loadPayment = () => {
    if (payLoading) return <CircularProgress style={{ float: 'right' }} />
    if (experience.price < 1) {
      return (
        <Pay
          whileHover={{
            scale: 1.2,
            transition: { duration: 0.2 }
          }}
        >
          Coming Soon
        </Pay>
      )
    }
    if (user.user.uid) {
      return (
        <Pay
          onClick={handleToggle}
          whileHover={{
            scale: 1.2,
            transition: { duration: 0.2 }
          }}
        >
          SECURE MY SPOT
        </Pay>
      )
    }
    return (
      <Pay
        onClick={e => navigate('/login')}
        whileHover={{
          scale: 1.2,
          transition: { duration: 0.2 }
        }}
      >
        SECURE MY SPOT
      </Pay>
    )
  }
  const loadPaymentBelow = () => {
    if (payLoading) return <CircularProgress style={{ float: 'right' }} />
    if (experience.price < 1) {
      return (
        <Pay
          whileHover={{
            scale: 1.2,
            transition: { duration: 0.2 }
          }}
        >
          Coming Soon
        </Pay>
      )
    }
    if (user.user.uid) {
      return (
        <Pay2
          style={{ marginTop: '50px' }}
          onClick={handleToggle}
          whileHover={{
            scale: 1.2,
            transition: { duration: 0.2 }
          }}
        >
          SECURE MY SPOT
        </Pay2>
      )
    }
    return (
      <Pay
        onClick={e => navigate('/login')}
        whileHover={{
          scale: 1.2,
          transition: { duration: 0.2 }
        }}
      >
        SECURE MY SPOT
      </Pay>
    )
  }
  const renderImages = () => {
    if (loading) {
      return <PhotoContainer2>LOADING</PhotoContainer2>
    }
    if (images.length < 1) {
      return <PhotoContainer2>NO IMAGES TO DISPLAY</PhotoContainer2>
    }
    return (
      <PhotoContainer2>
        <Photo2
          onClick={requestFullscreen}
          key={page}
          src={images[imageIndex]}
          custom={direction}
          variants={variants}
          initial='enter'
          animate='center'
          exit='exit'
          transition={{
            x: { type: 'spring', stiffness: 300, damping: 30 },
            opacity: { duration: 0.2 }
          }}
          drag='x'
          dragConstraints={{ left: 0, right: 0 }}
          dragElastic={1}
          onDragEnd={(e, { offset, velocity }) => {
            const swipe = swipePower(offset.x, velocity.x)

            if (swipe < -swipeConfidenceThreshold) {
              paginate(1)
            } else if (swipe > swipeConfidenceThreshold) {
              paginate(-1)
            }
          }}
        />
      </PhotoContainer2>
    )
  }

  const renderMetaData = () => {
    if (!isNotNilOrEmpty(experience)) return <CircularProgress />
    return (
      <TripMetaContainer>
        <Helmet>
          <title>{experience.location}</title>
        </Helmet>
        <Location>
          <Heading>{experience.location}</Heading>
          {loadPayment()}
          <AlertDialog
            toggle={openD}
            setToggle={setOpenD}
            handlePayment={handlePayment}
            handleInstallPayment={handleInstallPayment}
            loading={payLoading}
          />
        </Location>
        <Price>${experience.price}</Price>
        <Date>Start Date: {date}</Date>
        <Date>End Date: {endDate}</Date>
        <DesContainer>
          <Linkify>
            <Description>{`${experience.description}`}</Description>
          </Linkify>
          {loadPaymentBelow()}
        </DesContainer>
      </TripMetaContainer>
    )
  }
  console.log(experience.price)
  return (
    <>
      <div
        style={{
          color: 'white',
          textAlign: 'center',
          textTransform: 'uppercase',
          fontWeight: 'bold',
          fontFamily: 'Fira Sans Condensed',
          fontSize: '20px'
        }}
      >
        Experience
      </div>
      <div
        style={{
          color: 'white',
          fontFamily: 'Fira Sans Condensed',
          fontSize: '1.4vh',
          marginLeft: '5vw'
        }}
      >
        SLIDE IMAGE TO SEE MORE
      </div>
      <AnimatePresence initial={false} custom={direction}>
        {renderImages()}
        {renderMetaData()}
      </AnimatePresence>
    </>
  )
}
