import React, { useState, useEffect } from 'react'
import { useNavigate, Navigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { useAuth } from '../hooks/useAuth'
import { useInput } from '../hooks/use-input'
import CountrySelector from './CountrySelector'
import Selector from './Selector'
import { Grid } from '@mui/material'

const isNotEmpty = value => value.trim() !== ''
const isName = value => value.length > 2
const isPassword = value => value.length >= 6
const isEmail = value =>
  value
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
const isPassportNum = value => value.length >= 8
const isMobileNum = value => value.length >= 11

const darkTheme = createTheme({
  palette: {
    mode: 'dark'
  }
})

const navigateToLogin = navigate => e => {
  e.preventDefault()
  navigate('/login')
}

export const SignUp = () => {
  const [loggedIn, setLoggedIn] = useState(false)
  const {
    actions: { createUser }
  } = useAuth()

  useEffect(() => {
    const user = window.localStorage.getItem('user')
    if (user) {
      setLoggedIn(true)
    }
  }, [])

  const {
    value: mobileNumber,
    isValid: mobileNumberIsValid,
    hasError: mobileNumberError,
    valueChangeHandler: mobileNumberChangeHandler,
    inputBlurHandler: mobileNumberBlur,
    reset: resetMobileInput
  } = useInput(isMobileNum)

  const {
    value: passportNum,
    isValid: passportNumIsValid,
    hasError: passportNumError,
    valueChangeHandler: passportNumChangeHandler,
    inputBlurHandler: passportNumBlur,
    reset: resetPassportNumInput
  } = useInput(isPassportNum)

  const {
    value: passportIssue,
    isValid: passportIssueIsValid,
    hasError: passportIssueError,
    valueChangeHandler: passportIssueChangeHandler,
    inputBlurHandler: passportIssueBlur,
    reset: resetPassportIssueInput
  } = useInput(isNotEmpty)

  const {
    value: passportExp,
    isValid: passportExpIsValid,
    hasError: passportExpError,
    valueChangeHandler: passportExpChangeHandler,
    inputBlurHandler: passportExpBlur,
    reset: resetPassportExpInput
  } = useInput(isNotEmpty)

  const {
    value: emerg,
    isValid: emergIsValid,
    hasError: emergError,
    valueChangeHandler: emergChangeHandler,
    inputBlurHandler: emergBlur,
    reset: resetEmergInput
  } = useInput(isNotEmpty)

  const {
    value: where,
    isValid: whereIsValid,
    hasError: whereError,
    valueChangeHandler: whereChangeHandler,
    inputBlurHandler: whereBlur,
    reset: resetWhereInput
  } = useInput(isNotEmpty)

  const {
    value: concern,
    isValid: concernIsValid,
    hasError: concernError,
    valueChangeHandler: concernChangeHandler,
    inputBlurHandler: concernBlur,
    reset: resetConcernInput
  } = useInput(isNotEmpty)

  const {
    value: excite,
    isValid: exciteIsValid,
    hasError: exciteError,
    valueChangeHandler: exciteChangeHandler,
    inputBlurHandler: exciteBlur,
    reset: resetExciteInput
  } = useInput(isNotEmpty)

  const {
    value: email,
    isValid: emailIsValid,
    hasError: emailError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlur,
    reset: resetEmailInput
  } = useInput(isEmail)

  const {
    value: password,
    isValid: passwordIsValid,
    hasError: passwordError,
    valueChangeHandler: passwordChangeHandler,
    inputBlurHandler: passwordBlur,
    reset: resetPasswordInput
  } = useInput(isPassword)

  const {
    value: firstName,
    isValid: firstNameIsValid,
    hasError: firstNameError,
    valueChangeHandler: firstNameChangeHandler,
    inputBlurHandler: firstNameBlur,
    reset: resetFirstNameInput
  } = useInput(isName)

  const {
    value: lastName,
    isValid: lastNameIsValid,
    hasError: lastNameError,
    valueChangeHandler: lastNameChangeHandler,
    inputBlurHandler: lastNameBlur,
    reset: resetLastNameInput
  } = useInput(isName)

  let formIsValid = false

  if (
    emailIsValid &&
    passwordIsValid &&
    firstNameIsValid &&
    lastNameIsValid &&
    mobileNumberIsValid &&
    passportNumIsValid &&
    passportIssueIsValid &&
    passportExpIsValid &&
    emergIsValid &&
    whereIsValid &&
    concernIsValid &&
    exciteIsValid
  ) {
    formIsValid = true
  }

  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [signed, setSignUp] = useState(false)
  const [covid, setCovid] = useState('Yes')
  const [travel, setTravel] = useState('Yes')
  const [country, setCountry] = useState('US')
  const [covidFiles, setCovidFiles] = useState([])
  const [passFiles, setPassFiles] = useState([])

  const onCFileChange = e => {
    setCovidFiles(e.target.files)
  }

  const onPFileChange = e => {
    setPassFiles(e.target.files)
  }

  console.log('covid', covidFiles)
  console.log('passport', passFiles)
  const handleSubmit = async event => {
    try {
      event.preventDefault()
      const payload = {
        email,
        password,
        firstName,
        lastName,
        country,
        covid,
        passportExp,
        passportIssue,
        passportNum,
        mobileNumber,
        travel,
        emerg,
        where,
        concern,
        excite,
        covidFiles,
        passFiles
      }

      if (
        !emailIsValid &&
        !passwordIsValid &&
        !firstNameIsValid &&
        !lastNameIsValid &&
        !mobileNumberIsValid &&
        !passportNumIsValid &&
        !passportIssueIsValid &&
        !passportExpIsValid &&
        !emergIsValid &&
        !whereIsValid &&
        !concernIsValid &&
        !exciteIsValid
      )
        return
      console.log({ payload })
      setLoading(true)
      await createUser(payload)
      setLoading(false)
      resetEmailInput()
      resetPasswordInput()
      resetFirstNameInput()
      resetLastNameInput()
      resetMobileInput()
      resetPassportNumInput()
      resetPassportIssueInput()
      resetPassportExpInput()
      resetEmergInput()
      resetWhereInput()
      resetExciteInput()
      resetConcernInput()
      setSignUp(true)
    } catch (error) {
      setLoading(false)
      window.alert(error)
    }
  }
  const renderLoader = () => {
    if (loading) {
      return <CircularProgress />
    }
    if (signed) {
      return (
        <Alert
          action={
            <Button
              onClick={navigateToLogin(navigate)}
              color='inherit'
              size='small'
            >
              Click to Login
            </Button>
          }
        >
          Signed up succefully
        </Alert>
      )
    }
    return (
      <Button
        type='submit'
        variant='contained'
        fullWidth
        size='large'
        disabled={!formIsValid}
        sx={{
          mt: 3,
          mb: 2,
          fontFamily: 'Fira Sans Condensed',
          width: '40%',
          mr: 'auto',
          ml: 'auto'
        }}
      >
        Sign Up
      </Button>
    )
  }
  if (loggedIn) {
    return <Navigate to='/' />
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <Helmet>
        <title>Sign up</title>
      </Helmet>
      <Container component='main'>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography
            component='h1'
            variant='h5'
            sx={{ fontFamily: 'Fira Sans Condensed' }}
          >
            Sign Up
          </Typography>
          <Box
            component='form'
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1, flexGrow: 1 }}
          >
            <Grid
              container
              spacing={2}
              direction='row'
              justifyContent='center'
              alignItems='center'
            >
              <Grid item xs={10}>
                <TextField
                  error={emailError}
                  sx={{ fontFamily: 'Fira Sans Condensed' }}
                  margin='normal'
                  required
                  fullWidth
                  id='email'
                  label='Email Address'
                  name='email'
                  value={email}
                  type='email'
                  autoComplete='off'
                  onChange={emailChangeHandler}
                  autoFocus
                  onBlur={emailBlur}
                />
                {emailError && (
                  <p style={{ color: '#f44336' }}> Email Invalid!</p>
                )}
              </Grid>
              <Grid item xs={10}>
                <TextField
                  sx={{ fontFamily: 'Fira Sans Condensed' }}
                  margin='normal'
                  required
                  fullWidth
                  name='password'
                  label='Password'
                  value={password}
                  onChange={passwordChangeHandler}
                  type='password'
                  id='password'
                  autoComplete='off'
                  onBlur={passwordBlur}
                  error={passwordError}
                />
                {passwordError && (
                  <p style={{ color: '#f44336' }}>
                    Password must be 6 letters or more
                  </p>
                )}
              </Grid>
              <Grid item xs={10}>
                <TextField
                  sx={{ fontFamily: 'Fira Sans Condensed' }}
                  margin='normal'
                  required
                  fullWidth
                  id='firstName'
                  type='text'
                  label='First name'
                  name='firstName'
                  autoComplete='off'
                  value={firstName}
                  onChange={firstNameChangeHandler}
                  onBlur={firstNameBlur}
                  error={firstNameError}
                />
                {firstNameError && (
                  <p style={{ color: '#f44336' }}> First name is required!</p>
                )}
              </Grid>
              <Grid item xs={10}>
                <TextField
                  sx={{ fontFamily: 'Fira Sans Condensed' }}
                  margin='normal'
                  required
                  fullWidth
                  id='surName'
                  label='Surname'
                  name='lastName'
                  autoComplete='off'
                  type='text'
                  value={lastName}
                  onChange={lastNameChangeHandler}
                  onBlur={lastNameBlur}
                  error={lastNameError}
                />
                {lastNameError && (
                  <p style={{ color: '#f44336' }}> Surname required!</p>
                )}
              </Grid>
              <Grid item xs={10}>
                <TextField
                  sx={{ fontFamily: 'Fira Sans Condensed' }}
                  margin='normal'
                  required
                  fullWidth
                  id='number'
                  label='Mobile phone number'
                  type='number'
                  name='number'
                  value={mobileNumber}
                  onChange={mobileNumberChangeHandler}
                  onBlur={mobileNumberBlur}
                  error={mobileNumberError}
                />
                {mobileNumberError && (
                  <p style={{ color: '#f44336' }}>
                    Mobile number must include country code!
                  </p>
                )}
              </Grid>
              <Grid item xs={10}>
                <TextField
                  sx={{ fontFamily: 'Fira Sans Condensed' }}
                  margin='normal'
                  required
                  fullWidth
                  id='passport number'
                  label='Passport number'
                  autoComplete='off'
                  name='passport number'
                  type='text'
                  value={passportNum}
                  onChange={passportNumChangeHandler}
                  onBlur={passportNumBlur}
                  error={passportNumError}
                />
                {passportNumError && (
                  <p style={{ color: '#f44336' }}>
                    Valid passport number required!
                  </p>
                )}
              </Grid>
              <Grid item xs={10}>
                <CountrySelector
                  value={country}
                  selectedCountry={country}
                  onChanged={selected => setCountry(selected)}
                />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  sx={{ fontFamily: 'Fira Sans Condensed' }}
                  margin='normal'
                  required
                  fullWidth
                  type='date'
                  id='passort issue'
                  autoComplete='off'
                  label='Passport Issued on'
                  name='passport issue'
                  value={passportIssue}
                  onChange={passportIssueChangeHandler}
                  onBlur={passportIssueBlur}
                  error={passportIssueError}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
                {passportIssueError && (
                  <p style={{ color: '#f44336' }}>
                    Passport Issue Date required!
                  </p>
                )}
              </Grid>
              <Grid item xs={10}>
                <TextField
                  sx={{ fontFamily: 'Fira Sans Condensed' }}
                  margin='normal'
                  required
                  fullWidth
                  type='date'
                  InputLabelProps={{
                    shrink: true
                  }}
                  id='passport Expiration date'
                  label='Passport Expiration date'
                  name='passport Expiration date'
                  value={passportExp}
                  onChange={passportExpChangeHandler}
                  onBlur={passportExpBlur}
                  error={passportExpError}
                />
                {passportExpError && (
                  <p style={{ color: '#f44336' }}> Expiration Date required!</p>
                )}
              </Grid>
              <Grid item xs={10}>
                <TextField
                  sx={{ fontFamily: 'Fira Sans Condensed' }}
                  margin='normal'
                  required
                  fullWidth
                  type='text'
                  id='Emergency Contact'
                  label='Emergency Contact'
                  autoComplete='off'
                  name='Emergence Contact'
                  value={emerg}
                  onChange={emergChangeHandler}
                  onBlur={emergBlur}
                  error={emergError}
                />
                {emergError && (
                  <p style={{ color: '#f44336' }}>
                    Emergence Contacts required!
                  </p>
                )}
              </Grid>
              <Grid item xs={10}>
                <Selector
                  selected={covid}
                  onChanged={selected => setCovid(selected)}
                  text='Received your Covid vaccine?'
                />
              </Grid>
              <Grid item xs={10}>
                <Selector
                  selected={travel}
                  onChanged={selected => setTravel(selected)}
                  text='Have you ever traveled internationally as an adult?'
                />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  sx={{ fontFamily: 'Fira Sans Condensed' }}
                  margin='normal'
                  required
                  fullWidth
                  type='text'
                  id='If yes, Where?'
                  label='If yes, Where? else type NA'
                  name='If yes, Where?'
                  autoComplete='off'
                  value={where}
                  onChange={whereChangeHandler}
                  onBlur={whereBlur}
                  error={whereError}
                />

                {whereError && (
                  <p style={{ color: '#f44336' }}>Please do not leave empty!</p>
                )}
              </Grid>
              <Grid item xs={10}>
                <TextField
                  sx={{ fontFamily: 'Fira Sans Condensed' }}
                  margin='normal'
                  required
                  fullWidth
                  type='text'
                  id='What is your biggest concern about traveling with ANLO'
                  label='What is your biggest concern about traveling with ANLO'
                  name='What is your biggest concern about traveling with ANLO'
                  value={concern}
                  onChange={concernChangeHandler}
                  onBlur={concernBlur}
                  error={concernError}
                />

                {concernError && (
                  <p style={{ color: '#f44336' }}>Please do not leave empty!</p>
                )}
              </Grid>
              <Grid item xs={10}>
                <TextField
                  sx={{ fontFamily: 'Fira Sans Condensed' }}
                  margin='normal'
                  required
                  fullWidth
                  type='text'
                  id='What are you most exicted about?'
                  label='What are you most exicted about?'
                  name='What are you most exicted about?'
                  value={excite}
                  onChange={exciteChangeHandler}
                  onBlur={exciteBlur}
                  error={exciteError}
                />
                {exciteError && (
                  <p style={{ color: '#f44336' }}>Please do not leave empty!</p>
                )}
              </Grid>

              <Grid item xs={10}>
                <TextField
                  type='file'
                  sx={{
                    fontFamily: 'Fira Sans Condensed'
                  }}
                  margin='normal'
                  required
                  fullWidth
                  label='Upload photo page of your passport below'
                  name='upload'
                  InputLabelProps={{
                    shrink: true
                  }}
                  inputProps={{ accept: 'image/*', multiple: true }}
                  onChange={onCFileChange}
                />
                <TextField
                  type='file'
                  sx={{
                    fontFamily: 'Fira Sans Condensed'
                  }}
                  margin='normal'
                  required
                  fullWidth
                  label='Upload photo page of your Covid vaccine card below'
                  name='upload'
                  InputLabelProps={{
                    shrink: true
                  }}
                  inputProps={{ accept: 'image/*', multiple: true }}
                  onChange={onPFileChange}
                />
              </Grid>
              {renderLoader()}
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  )
}
