import * as React from 'react'
import Backdrop from '@mui/material/Backdrop'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import { getDatabase, ref as dbRef, set } from 'firebase/database'
import Fade from '@mui/material/Fade'
import Typography from '@mui/material/Typography'
import { Button, CircularProgress, TextField } from '@mui/material'
import { useInput } from '../hooks/use-input'
import { createTheme, ThemeProvider } from '@mui/material/styles'

const darkTheme = createTheme({
  palette: {
    mode: 'dark'
  }
})

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'black',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: '14px',
  color: 'white'
}

export default function NewsLetter ({ open, setOpen }) {
  const [loading, setLoading] = React.useState(false)
  const isEmail = value =>
    value
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )

  const {
    value: email,
    isValid: emailIsValid,
    hasError: emailError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlur,
    reset: resetEmailInput
  } = useInput(isEmail)

  let formIsValid = false

  if (emailIsValid) {
    formIsValid = true
  }

  const handleClose = () => setOpen(false)

  const handleSubmit = async e => {
    e.preventDefault()
    const db = getDatabase()
    setLoading(true)
    await set(dbRef(db, 'newsletter/'), {
      email
    })
    window.alert('You have subscribed to our newsletter')
    setLoading(false)
    resetEmailInput()
    setOpen(false)
  }

  const renderLoader = () => {
    if (loading) {
      return <CircularProgress />
    }

    return (
      <Button
        variant='contained'
        fullWidth
        size='large'
        onClick={handleSubmit}
        sx={{
          fontFamily: 'Fira Sans Condensed'
        }}
        disabled={!formIsValid}
      >
        Subscribe
      </Button>
    )
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography id='transition-modal-title' variant='h4' component='h2'>
              Stay In Touch
            </Typography>

            <Typography id='transition-modal-title' variant='h6' component='h2'>
              Get information on future Anlo Experiences
            </Typography>
            <TextField
              fullWidth
              type='text'
              label='email'
              name='email'
              sx={{ fontFamily: 'Fira Sans Condensed' }}
              value={email}
              margin='normal'
              onChange={emailChangeHandler}
              onBlur={emailBlur}
              error={emailError}
            />
            {emailError && <p style={{ color: '#f44336' }}> Email Invalid!</p>}
            {renderLoader()}
          </Box>
        </Fade>
      </Modal>
    </ThemeProvider>
  )
}
