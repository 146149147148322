import styled from 'styled-components'
import { motion } from 'framer-motion'

export const Container = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=Fira+Sans+Condensed&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Sorts+Mill+Goudy&display=swap');
  background-color: black;
  width: 100vw;
  height: 5vh;
  margin-top: 0px;
  display: flex;
  justify-content: space-between;
  position: relative;
`
export const LogoContainer = styled.div`
  /* background-color: red; */
  width: 20%;
  height: 4vh;
  float: left;
  @media (max-width: 768px) {
    width: 40vw;
    margin-left: -10vw;
  }
`
export const Logo = styled.img`
  /* background-color: red; */
  margin-top: 1vh;
  margin-left: 0.5vw;
  width: inherit;
  height: inherit;
  float: left;
  transform: scale(3);
  @media (max-width: 768px) {
    width: 40vw;
  }
`
export const LinksContainer = styled.div`
  /* background-color: red; */
  width: 100%;
  height: 5vh;
  @media (max-width: 768px) {
    display: none;
  }
`
export const LinksUL = styled.ul`
  /* background-color: orange; */
  width: 70%;
  height: 5vh;
  float: right;
  /* display:flex; */
`
export const LinksL = styled(motion.li)`
  cursor: pointer;
  color: white;
  /* width: 15%; */
  height: 3vh;
  margin-right: 1.2vw;
  float: right;
  font-family: 'Roboto', sans-serif;
  display: inline-block;
  list-style-type: none;
  margin-top: 1.45vh;
  font-size: 1.1vh;
`

export const AnloLogo = styled.img`
  width: 100%; /* or any custom size */
  height: 100%;
  object-fit: contain;
`
