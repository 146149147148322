import React, { useEffect, useState } from 'react'
import { useCycle } from 'framer-motion'
import { Link } from 'react-router-dom'
import Linkify from 'react-linkify'
import { Helmet } from 'react-helmet'
import { getDatabase, ref as dbRef, onValue } from 'firebase/database'
// import InstagramIcon from '@mui/icons-material/Instagram'
import { isNilOrEmpty } from '@meltwater/phi'
import CircularProgress from '@mui/material/CircularProgress'
import ReactPlayer from 'react-player'
import { transform } from '../utils/index'
import instagram from '../assets/Instagram_icon.png'

import {
  BannerContainer,
  BannerImage,
  WatchVideo,
  BannerTextContainer,
  BannerText,
  VideoContainer,
  UpcomingTripsContainer,
  Trip,
  TripImage,
  TripContainer,
  BookNow
} from '../styles/home'
import NewsLetter from './Modal'

export const Home = () => {
  const [loadingE, setLoadingE] = useState(true)
  const [trips, setTrips] = useState([])
  const [meta, setMeta] = useState([])
  const [onVideo, onCycle] = useCycle(false, true)
  const [open, setOpen] = useState(false)
  const fetchExperiences = () => {
    const db = getDatabase()
    onValue(
      dbRef(db, 'experiences/'),
      snapshot => {
        console.log(transform(snapshot.val()))
        setTrips(transform(snapshot.val()))
        setLoadingE(false)
      },
      { onlyOnce: true }
    )
    setLoadingE(false)
  }
  const fetchHomeMeta = () => {
    const db = getDatabase()
    onValue(
      dbRef(db, 'home/metadata/'),
      snapshot => {
        setMeta(snapshot.val())
      },
      { onlyOnce: true }
    )
  }
  useEffect(() => {
    fetchExperiences()
    fetchHomeMeta()
    setTimeout(() => {
      setOpen(true)
    }, 5000)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const renderTrips = () => {
    if (isNilOrEmpty(trips)) {
      return <CircularProgress />
    }
    return (
      <Link style={{ textDecoration: 'none' }} to='/experiences'>
        <Trip>
          <h1>{trips[trips.length - 1].location}</h1>
          <TripImage
            whileHover={{
              opacity: 0.4,
              transition: { duration: 0.2 }
            }}
            src={trips[trips.length - 1].images[0]}
          />
          <BookNow>View Experiences</BookNow>
        </Trip>
      </Link>
    )
  }

  const renderBanner = () => {
    if (loadingE) return <CircularProgress />
  }
  return (
    <>
      <Helmet>
        <title>Anlo Travel</title>
      </Helmet>
      <BannerContainer>
        <NewsLetter open={open} setOpen={setOpen} />
        {onVideo ? (
          <VideoContainer
            key='123'
            initial={{ opacity: 0.2 }}
            animate={{ opacity: 1 }}
          >
            <ReactPlayer
              url={meta.ytLink}
              controls={true}
              playing={true}
              width='100%'
              height='100%'
            />
          </VideoContainer>
        ) : (
          <BannerImage
            src={meta.frontImgUrl}
            initial={{ opacity: 0.5 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.5 }}
          />
        )}
        <BannerTextContainer>
          {onVideo ? (
            <WatchVideo
              whileHover={{
                scale: 1.2,
                transition: { duration: 0.2 }
              }}
              onClick={onCycle}
            >
              CLOSE VIDEO
            </WatchVideo>
          ) : (
            <WatchVideo
              whileHover={{
                scale: 1.2,
                transition: { duration: 0.2 }
              }}
              onClick={onCycle}
            >
              PLAY VIDEO
            </WatchVideo>
          )}
          <Linkify>
            <BannerText>
              {meta.frontText}
              <br />
              <a
                href='https://instagram.com/anlotravel?utm_medium=copy_link'
                target='_blank'
                rel='noreferrer'
              >
                <img src={instagram} alt='insta' />
              </a>
            </BannerText>
          </Linkify>
        </BannerTextContainer>
      </BannerContainer>
      <UpcomingTripsContainer>
        {renderBanner()}
        <TripContainer>{renderTrips()}</TripContainer>
      </UpcomingTripsContainer>
    </>
  )
}
