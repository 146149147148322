import './App.css'
import { createGlobalStyle } from 'styled-components'
import { useEffect, useState } from 'react'

import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { useAuth } from './hooks/useAuth'
import { Routes, Route } from 'react-router-dom'
import { Navbar } from './components/Navbar'
import { Home } from './components/Home'
import { Photos } from './components/Photos'
import { Upload } from './components/Upload'
import { Dashboard } from './components/Dashboard'
import { Trip } from './components/Trip'
import { Login } from './components/Login'
import { SignUp } from './components/SignUp'
import { Experience } from './components/Experience'
import { Experiences } from './components/Experiences'
import { Frontpage } from './components/Frontpage'
import Policy from './components/Policy'
import ProtectedRoute from './components/Protected'
import Users from './components/Users'
import Success from './components/Success'
import Failure from './components/Failure'
import { EditExperience } from './components/EditExperience'
import { AdminTrip } from './components/AdminTrip'
import { AdminExperiences } from './components/AdminExperiences'
import UserDashBoard from './components/UserDashBoard'
import AdminDashBoard from './components/AdminDashboard'

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background: teal;
    font-family: Open-Sans, Helvetica, Sans-Serif;
    background-color: black;

  }
`

function App () {
  const {
    actions: { currentUser }
  } = useAuth()
  const [showAdminMenu, setAdmin] = useState(false)
  const auth = getAuth()
  useEffect(() => {
    onAuthStateChanged(auth, user => {
      if (user) {
        currentUser(user)
        window.localStorage.setItem('user', user.uid)
        if (user.uid === 'Rqpa4oEh9uYsokamsGAvGzvgnM13') {
          setAdmin(true)
        }
      } else {
        currentUser({})
        window.localStorage.removeItem('user')
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const renderAdminMenu = () => {
    if (showAdminMenu) return <Dashboard />
  }
  return (
    <div className='App' style={{ overflow: 'hidden' }}>
      <GlobalStyle />
      <Navbar />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='photos' element={<Photos />} />
        <Route
          path='admin/upload'
          element={<ProtectedRoute component={Upload} />}
        />
        <Route path='experiences/:id' element={<Trip />} />
        <Route path='login' element={<Login />} />
        <Route path='857856759-3/signup' element={<SignUp />} />
        <Route path='policy' element={<Policy />} />
        <Route path='experiences' element={<Experiences />} />
        <Route path='profile' element={<UserDashBoard />} />
        <Route
          path='admin/users'
          element={<ProtectedRoute component={Users} />}
        />
        <Route
          path='admin/users/:id'
          element={<ProtectedRoute component={AdminDashBoard} />}
        />

        <Route
          path='admin/frontpage'
          element={<ProtectedRoute component={Frontpage} />}
        />
        <Route
          path='admin/experience'
          element={<ProtectedRoute component={Experience} />}
        />
        <Route
          path='admin/experiences'
          element={<ProtectedRoute component={AdminExperiences} />}
        />
        <Route
          path='admin/experiences/:id'
          element={<ProtectedRoute component={AdminTrip} />}
        />

        <Route
          path='admin/experiences/:id/editexperience'
          element={<ProtectedRoute component={EditExperience} />}
        />
        <Route path='success' element={<Success />} />
        <Route path='failure' element={<Failure />} />
      </Routes>
      {renderAdminMenu()}
    </div>
  )
}

export default App
