import { Avatar, CircularProgress } from '@mui/material'

import { getDatabase, ref as dbRef, onValue } from 'firebase/database'

import React, { useEffect, useState } from 'react'

import { useParams } from 'react-router-dom'

import { Container, ProfileWrapper, TextContainer } from '../styles/adminDash'

const AdminDashBoard = () => {
  const [loading, setLoading] = useState(false)
  const [curUser, setCurUser] = useState({})
  // const [docs, setDocs] = useState([])

  const { id } = useParams()

  const fetchCurrentUser = id => {
    const db = getDatabase()
    onValue(
      dbRef(db, `users/${id}`),
      snapshot => {
        setCurUser(snapshot.val())
        setLoading(false)
      },
      { onlyOnce: true }
    )
  }

  useEffect(() => {
    setLoading(true)
    fetchCurrentUser(id)
  }, [id])

  console.log(curUser)

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '90vh'
        }}
      >
        <CircularProgress />
      </div>
    )
  }
  const {
    firstName,
    lastName,
    email,
    mobileNumber,
    passportNum,
    country,
    passportIssue,
    passportExp,
    passFiles,
    emerg,
    covid,
    covidFiles,
    travel,
    where,
    travelData,
    concern,
    excite
  } = curUser

  console.log(travelData)

  let list = []

  for (const key in travelData) {
    list.push({
      url: travelData[key]
    })
  }

  console.log(list)

  list.map(url => console.log(url.url))

  return (
    <Container>
      <ProfileWrapper>
        <Avatar
          sx={{ m: 1, bgcolor: 'secondary.main', width: 70, height: 70 }}
        />
      </ProfileWrapper>
      <TextContainer>
        <div>
          <span style={{ textTransform: 'capitalize', marginBottom: '5px' }}>
            <b>Name:</b>
            {` ${firstName} ${lastName}`}
          </span>
        </div>
        <div>
          <span>
            <b>Email:</b> {email}
          </span>
        </div>
        <div>
          <span>
            <b>Number:</b> {mobileNumber}
          </span>
        </div>
        <div>
          <span>
            <b>Passport Number:</b> {passportNum}
          </span>
        </div>
        <div>
          <span>
            <b>Country:</b> {country}
          </span>
        </div>
        <div>
          <span>
            <b>Passport Issue Date:</b> {passportIssue}
          </span>
        </div>
        <div>
          <span>
            <b>Passport Expiration Date:</b> {passportExp}
          </span>
        </div>
        <div>
          <span>
            <b>Passport files:</b>
            <br />
            <img src={passFiles} alt='passport' />
          </span>
        </div>
        <div>
          <span>
            <b>Emergency Contact:</b> {emerg}
          </span>
        </div>
        <div>
          <span>
            <b>Received your Covid vaccine?</b> {covid}
          </span>
        </div>
        <div>
          <span>
            <b>Covid files:</b>
            <br />

            <img src={covidFiles} alt='covid' />
          </span>
        </div>
        <div>
          <span>
            <b>Have you ever traveled internationally as an adult? </b>
            {travel}
          </span>
        </div>
        <div>
          <span>
            <b>If yes, Where? </b>
            {where}
          </span>
        </div>
        <div>
          <span>
            <b>Travel Docs: </b>
            <br />

            {list.map((data, index) =>
            {
              return (
                <div key={index}>
                  <img src={data.url} alt='travel docs' />
                </div>
              )
            }
            )}
          </span>
        </div>
        <div>
          <span>
            <b>What is your biggest concern about traveling with ANLO: </b>
            {concern}
          </span>
        </div>
        <div>
          <span>
            <b>What are you most exicted about?</b> {excite}
          </span>
        </div>
      </TextContainer>
    </Container>
  )
}

export default AdminDashBoard
