import styled from 'styled-components'
import { motion } from 'framer-motion'

const buttonStyles = `
top: calc(50% - 20px);
  position: absolute;
  background: white;
  border-radius: 30px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 18px;
  z-index: 2;
`
export const PhotoContainer = styled(motion.div)`
  width: 100vw;
  /* background-color:red; */
  color: white;
  height: 85vh;
  position: relative;
  display: flex;
  font-family: 'Roboto', sans-serif;  
  justify-content: center;
  align-items: center;
`
export const PhotoContainer2 = styled(motion.div)`
  width: 50vw;
  /* background-color:red; */
  position: fixed;
  color: white;
  height: 85vh;
  display: flex;
  font-family: 'Roboto', sans-serif;  
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    width: 100vw;
    position: relative;
    /* background-color: red; */
  }
`

export const Photo = styled(motion.img)`
  position: absolute;
  max-width: 90vw;
  max-height: 75vh;
`
export const Photo2 = styled(motion.img)`
    position: absolute;
    max-width: 40vw;
    max-height: 80vh;
    left: 5vw;
    max-width: '40vw';
    @media (max-width: 768px) {
    max-width: 80vw;
    left: 10vw;
  }
   `
export const Next = styled.div`
  ${buttonStyles}
  right: 10px;
`
export const Prev = styled.div`
  ${buttonStyles}
  left: 10px;
  transform: scale(-1);
`
