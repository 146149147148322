/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Avatar from '@mui/material/Avatar'
import { Helmet } from 'react-helmet'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { useAuth } from '../hooks/useAuth'

const darkTheme = createTheme({
  palette: {
    mode: 'dark'
  }
})

export const Login = () => {
  const [loggedIn, setLoggedIn] = useState(false)
  const [pageLoading, setPage] = useState(true)
  const {
    actions: { loginUser, resetUser },
    state: { user }
  } = useAuth()
  useEffect(() => {
    if (user.user.uid) {
      setLoggedIn(true)
      setPage(false)
    }

    setPage(false)
  }, [user])
  const navigate = useNavigate()
  const [form, setForm] = useState({ email: '', password: '' })
  const [error, setError] = useState(false)
  const [errorText, setErrorText] = useState('')
  const [loading, setLoading] = useState(false)
  const handleChange = e => {
    setError(false)
    setErrorText(false)
    setForm({
      ...form,
      [e.target.name]: e.target.value
    })
  }
  const handleSubmit = async event => {
    try {
      event.preventDefault()
      setLoading(true)
      await loginUser(form)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setError(true)
      setErrorText(error.message)
    }
  }
  const handleReset = async event => {
    try {
      event.preventDefault()
      setLoading(true)
      await resetUser(form)
      setLoading(false)
      window.alert('Password reset email succefully sent')
    } catch (error) {
      setLoading(false)
      setError(true)
      setErrorText(error.message)
    }
  }
  const renderLoader = () => {
    if (loading) {
      return <CircularProgress />
    }
    return (
      <Button
        type='submit'
        fullWidth
        variant='contained'
        sx={{ mt: 3, mb: 2, fontFamily: 'Fira Sans Condensed' }}
      >
        Sign In
      </Button>
    )
  }
  if (loggedIn) {
    navigate('/')
  }
  if (pageLoading) {
    return <CircularProgress />
  }
  return (
    <ThemeProvider theme={darkTheme}>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <Container component='main' maxWidth='xs'>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography
            component='h1'
            variant='h5'
            sx={{ fontFamily: 'Roboto, sans-serif' }}
          >
            Sign in
          </Typography>
          <Box
            component='form'
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              error={error}
              sx={{ fontFamily: 'Roboto, sans-serif' }}
              margin='normal'
              required
              fullWidth
              id='email'
              label='Email Address'
              name='email'
              value={form.email}
              autoComplete='email'
              onChange={handleChange}
              autoFocus
              helperText={errorText}
            />
            <TextField
              sx={{ fontFamily: 'Roboto, sans-serif' }}
              margin='normal'
              required
              fullWidth
              name='password'
              label='Password'
              value={form.password}
              onChange={handleChange}
              type='password'
              id='password'
              autoComplete='current-password'
            />
            {renderLoader()}
            <Typography
              component='h2'
              variant='h5'
              sx={{ fontFamily: 'Roboto, sans-serif', fontSize: '20px' }}
            >
              Forgot Password? Fill in email and Click
              <a onClick={handleReset} href=''> here</a> to reset

            </Typography>
            <br />
            <Typography
              component='h1'
              variant='h5'
              sx={{ fontFamily: 'Roboto, sans-serif', fontSize: '20px' }}
            >
              Don't have an account and interested? Anlo is invite only, click{' '}
              <a href='mailto:anlotravel@gmail.com'>here</a> to contact us for
              an invitation
            </Typography>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  )
}
