import { motion } from 'framer-motion'
import styled from 'styled-components'

export const MobileLinksUl = styled.ul`
  a {
    text-decoration: none;
  }
`

export const MobileLinks = styled(motion.li)`
  cursor: pointer;
  color: black;
  height: 3vh;
  margin-right: 1.2vw;
  list-style-type: none;
  font-size: 2rem;
  text-transform: uppercase;
  padding: 2rem 0;
  font-weight: bold;
  font-family: 'Roboto', sans-serif;  
  font-size: 1.3vh;
  color: black;
  text-decoration: none;
`

export const StyledMobileOptionsContainerUnderlay = styled.div`
  background: rgba(255, 255, 255, 0.5);
  height: 100vh;
  position: absolute;
  top: 0;
  z-index: 10;
  width: 100vw;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (min-width: 400px) {
    align-items: center;
  }
  @media only screen and (min-width: 769px) {
    display: none;
  }
`

export const StyledMobileOptionsContainer = styled(motion.div)`
  position: fixed;
  top: 0px;
  height: 100vh;
  z-index: 10;
  width: 100vw;
  font-weight: bold;
  display: flex;
  font-family: 'Roboto', sans-serif;  
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  transition: transform 0.3s cubic-bezier(0, 0.52, 0, 1);
  @media only screen and (min-width: 769px) {
    display: none;
  }
`

export const HamMenu = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='27'
      height='18'
      viewBox='0 0 27 18'
    >
      <path
        id='Icon_material-menu'
        data-name='Icon material-menu'
        d='M4.5,27h27V24H4.5Zm0-7.5h27v-3H4.5ZM4.5,9v3h27V9Z'
        transform='translate(-4.5 -9)'
        fill='#fff'
      />
    </svg>
  )
}

export const CloseMenu = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='14.957'
      height='14.957'
      viewBox='0 0 14.957 14.957'
    >
      <path
        id='Icon_material-close'
        data-name='Icon material-close'
        d='M22.457,9.006,20.95,7.5l-5.972,5.972L9.006,7.5,7.5,9.006l5.972,5.972L7.5,20.95l1.506,1.506,5.972-5.972,5.972,5.972,1.506-1.506-5.972-5.972Z'
        transform='translate(-7.5 -7.5)'
        fill='#000'
      />
    </svg>
  )
}

export const StyledHamContainer = styled.div`
  margin-top: 8px;
  margin-right: 5px;
  float: right;
  z-index: 1000;
  font-family: 'Roboto', sans-serif;  
  @media only screen and (min-width: 769px) {
    display: none;
  }
`

export const StyledMobileOptionItem = styled(MobileLinks)`
  justify-content: center;
  font-family: 'Roboto', sans-serif;  
`

export const StyledUnderlay = styled.div`
  background-color: white;
  width: 100vw;
  height: 100vh;
  transition: transform 0.3s ease-in-out;
  position: absolute;
  font-family: 'Roboto', sans-serif;  
  top: 0;
  left: 0;
  z-index: 9;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`
