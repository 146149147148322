import React from 'react'
import { Helmet } from 'react-helmet'
// import FileViewer from 'react-file-viewer'

const file =
  'https://firebasestorage.googleapis.com/v0/b/anlotravel-2e6dc.appspot.com/o/policy%2FAnlo%20Travel%20Policy.pdf?alt=media&token=2170138c-9ff0-49a1-a412-f8a9d96e6b8b'
// import {
//   Congratulations,
//   Container,
//   Content,
//   Disclaimer,
//   Heading,
//   ListUl,
//   Paragraph
// } from '../styles/policy'

const Policy = () => {
  return (
    <>
      <Helmet>
        <title>Travel Policy</title>
      </Helmet>
      <object
        style={{
          position: 'absolute',
          top: '45%',
          width: '80vw',
          height: '80vh',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}
        data={file}
        type='application/pdf'
      >
        {' '}
      </object>
    </>
  )

  // return (
  //   <Container>
  //     <Content>
  //       <Congratulations>
  //         Congratulations on making a decision to participate in this amazing
  //         experience! Anlo has curated the best experience possible! Please read
  //         this contract thoroughly.
  //       </Congratulations>
  //       <Disclaimer>DISCLAIMER</Disclaimer>
  //       <Paragraph>
  //         YOU EXPRESSLY AGREE THAT BY PURCHASING AN EXPERIENCE WITH ANLO, YOU
  //         ACCEPT, AGREE, AND UNDERSTAND THAT TRIP DETAILS ARE SUBJECT TO CHANGE.
  //       </Paragraph>
  //       <Paragraph>
  //         The itinerary is a general guide to the tour and region and any
  //         mention of specific destinations is by no means a guarantee that they
  //         will be visited or encountered, nor does it form part of the contract
  //         between you and the operator. Although the itinerary has been curated
  //         with care and details are provided in good faith, we may make
  //         improvements that stem from weather changes, local factors, Covid 19
  //         precautions and our own research, to name a few examples. Ultimately,
  //         our goal is to provide you with the most rewarding experience.
  //         Nevertheless, to ensure the most accurate itinerary, it is strongly
  //         advised that you print a final copy of your itinerary to review a
  //         couple of days prior to travel, in case there have been changes that
  //         affect your plans. You also accept and agree that there are inherent
  //         risks associated with physical activities you may participate in.
  //         Therefore, you expressly acknowledge and assume responsibility for
  //         such risk and that you alone are responsible for your decision to
  //         participate in such activities.
  //       </Paragraph>
  //       <Paragraph>
  //         <strong>PRICES:</strong> Prices are per person based upon shared twin
  //         room occupancy. All prices are based on current exchange rates and are
  //         subject to change without notice. Prices are correct at time of
  //         publication. However, In case of human or computer error, Anlo
  //         reserves the right to re-invoice for the correct price or service.
  //       </Paragraph>
  //       <Heading>All lodging and excursions will be booked by Anlo.</Heading>
  //       <Heading>
  //         Unless otherwise stated in experience details, the price does not
  //         include:
  //       </Heading>
  //       <ListUl>
  //         <li>Food and beverages</li>
  //         <li>Visa fees</li>
  //         <li>Insurance and foreign port taxes</li>
  //         <li>Individual Grocery</li>
  //         <li>Personal shopping and interests</li>
  //         <li>Travel insurance</li>
  //         <li>Transportation on personal errands/activities</li>
  //         <li>Other/extra excursions</li>
  //       </ListUl>
  //       <Paragraph>
  //         <b>FORM OF PAYMENT: </b>Full payment at the time of booking or
  //         Afterpay for installments.
  //       </Paragraph>
  //       <Paragraph>
  //         <b>CANCELLATION: </b> Once a partial or full payment has been made,
  //         cancellations will only be accepted in writing or direct phone
  //         contact. Cancellation penalties apply to packages and/or services as
  //         indicated below. Airbnb non-refundable fees and other non-refundable
  //         fees imposed by vendors and suppliers.
  //       </Paragraph>
  //       <Paragraph>
  //         <b>PERSONAL INFORMATION:</b> Passengers are responsible for providing
  //         Anlo with their name as it appears on their passport, date of birth,
  //         gender, address, phone number and email. Passengers must ensure that
  //         names are correctly listed on their invoice. Anlo will not be
  //         responsible for passengers who do not receive airline invoices or
  //         documents or are denied boarding due to inaccurate information.
  //       </Paragraph>
  //       <Paragraph>
  //         <b>PASSPORTS AND VISAS:</b> Passengers are responsible for ensuring
  //         that they have the proper travel documents. passports are required to
  //         be valid for at least 6 months after the date of travel. Some
  //         destinations require VISAs, Anlo will inform you when that is the
  //         case.
  //       </Paragraph>
  //       <Paragraph>
  //         <b>TRAVEL PROTECTION (Insurance):</b> Missing a vacation is bad
  //         enough. Losing the money you paid for your vacation is even worse.
  //         Trip insurance is therefore mandatory. Insurance must include medical
  //         evacuation. Travel Protection varies with the type of policy
  //         purchased, and should be purchased as early as possible prior to
  //         departure. View suggested insurance companies.
  //       </Paragraph>
  //       <Heading>
  //         Proof of purchase of travel insurance must be uploaded 10 days before
  //         departure.
  //       </Heading>
  //       <Paragraph>
  //         <b>MEDICAL CONDITIONS AND SPECIAL REQUIREMENTS:</b> All Passengers
  //         should consult their physician regarding their fitness for travel. You
  //         are encouraged to seek a physician’s advice regarding necessary or
  //         advisable vaccinations, medical precautions, or other medical concerns
  //         regarding traveling to Africa. Until further notice, Covid vaccines
  //         are required to travel and Covid testing is often required as well.
  //         Passengers are responsible for making sure they have the correct Covid
  //         test, within the correct time frame. Passengers must notify Anlo in
  //         writing, prior to booking, of any medical conditions, pregnancy,
  //         disability or any other mental and or physical condition which may
  //         impact your fitness to travel, and/or any continuing medical
  //         condition. Anlo may refuse to carry pregnant persons over 24 weeks or
  //         Passengers with certain conditions, if suitable accommodation or
  //         alternate products or services cannot be arranged.
  //       </Paragraph>
  //       <Paragraph>
  //         <b>ACCEPTANCE OF RISK:</b>
  //         The Passenger acknowledges that the nature of activities on this trip
  //         and of this trip may involve a significant amount of risk to
  //         Passenger's's health and/or safety. The Passenger hereby assumes all
  //         such risk and does hereby release Anlo from all claims and causes of
  //         action arising from any damages or injuries or death resulting from
  //         these risks inherent in travel, visiting foreign destinations, and
  //         participating in adventurous activities such as those included in the
  //         itinerary. Furthermore, Anlo shall not be liable in any way to the
  //         passenger for all risks mentioned above.
  //       </Paragraph>
  //       <Paragraph>
  //         Accommodation, transportation, activities and excursions, and other
  //         components of this travel experience will be arranged by Anlo with
  //         suppliers local to the destination. Standards of hygiene,
  //         accommodation and transport in the country where tours take place may
  //         be lower than comparable standards than what the passenger may
  //         reasonably expect in their home country. Anlo at all times endeavors
  //         to appoint reputable and competent local suppliers and to comply with
  //         all applicable laws and regulations concerning health and safety in
  //         the regions where tours operate.{' '}
  //       </Paragraph>
  //       <Paragraph>
  //         <b>AUTHORITY ON TOUR & PASSENGER RESPONSIBILITY:</b> At all times the
  //         decision of the Experience Designer (Damola S.) or other designated
  //         representative will be final on all matters regarding safety and well
  //         being of passengers and operational requirements of the experience. By
  //         traveling with Anlo, you agree to abide by the authority of the
  //         Experience Designer or designated representative. You must at all
  //         times strictly comply with all applicable laws and regulations of all
  //         areas visited.{' '}
  //       </Paragraph>
  //       <Paragraph>
  //         Should the passenger fail to comply with the above or commit any
  //         illegal act when on the trip/tour or, if in the opinion of the Damola
  //         S., the passenger’s behavior is causing or is likely to cause danger
  //         or distress to others, Anlo may terminate that passengers travel
  //         arrangements on any Product immediately without any liability on
  //         Anlo’s part and the passenger will not be entitled to any refund for
  //         unused or missed services or costs incurred resulting from the
  //         termination of the travel arrangements, including, without limitation,
  //         return travel, accommodations, meals, and/or incidentals.
  //       </Paragraph>
  //       <Paragraph>
  //         Passengers agree that they are responsible for any costs incurred by
  //         Anlo, as a result of damage, destruction, theft, or excess cleaning
  //         fees related to passengers accommodation, transport, or other use of
  //         facilities while on the trip/tour. Passengers agree to immediately
  //         report any pre-existing damage of this kind to staff of the
  //         accommodation, transportation service, or facility (as appropriate)
  //         and/or a Damola S. as soon as possible upon discovery.
  //       </Paragraph>
  //       <Paragraph>
  //         Passengers agree to take all prudent measures in relation to their own
  //         safety, including, but not limited to, the proper use of safety
  //         devices such as seatbelts, harnesses, and helmets, and obeying all
  //         posted signs and warnings in relation to health and safety. Anlo shall
  //         not be liable for any failure on the passenger’s part to comply with
  //         this paragraph.
  //       </Paragraph>
  //       <Paragraph>
  //         The Contract and these Terms are subject to the laws of New Jersey,
  //         and all Passengers submit to the exclusive jurisdiction of the courts
  //         located in Sayreville, New Jersey, United States for the resolution of
  //         any dispute under these Terms or concerning a Product.
  //       </Paragraph>
  //       <Paragraph>
  //         <b>INDEMNIFICATION.</b> I, individually, hereby agree to indemnify,
  //         defend, and hold harmless Anlo and Damola Sulaiman from any and all
  //         liability, loss, damage, or expense, including attorneys fees, which
  //         arise out of, occur during, or are in any way connected with my
  //         participation in this travel experience, tours and activities or any
  //         travel incident thereto.
  //       </Paragraph>
  //     </Content>
  //   </Container>
  // )
}

export default Policy
