import React from 'react'
import { StyledMenu } from '../styles/dashMenu'

export const DashMenu = ({ open }) => {
  return (
    <StyledMenu open={open}>
      <a href='/admin/frontpage'>Update Frontpage</a>
      <a href='/admin/upload'>UPLOAD PHOTOS</a>
      <a href='/admin/experience'>CREATE EXPERIENCE</a>
      <a href='/admin/experiences'>View Experience</a>
      <a href='/admin/users'>USERS</a>
    </StyledMenu>
  )
}
