import axios from 'axios'

export const getImages = async () => {
  console.log(`${process.env.REACT_APP_FILE_SERVICE_URL}/prod/get-image`)
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': process.env.REACT_APP_FILE_API_KEY
      }
    }
    console.log(config)
    const res = await axios.get(
      `${process.env.REACT_APP_FILE_SERVICE_URL}/prod/get-image`,
      config
    )

    return res.data.body
  } catch (err) {
    return []
  }
}

// export const images = [
//   'https://images.unsplash.com/photo-1643674401637-32d1bc6cd509?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80',
//   'https://images.unsplash.com/photo-1643765922898-5b13dd72e26b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80',
//   'https://images.unsplash.com/photo-1643754713484-eb1a6bfccd76?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80',
//   'https://images.unsplash.com/photo-1643712153726-c7add943f4ba?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80',
//   'https://images.unsplash.com/photo-1643706914001-d610dc32aade?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1065&q=80',
//   'https://images.unsplash.com/photo-1589556045897-c444ffa0a6ff?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1974&q=80'
// ]
