import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import CssBaseline from '@mui/material/CssBaseline'
import TextareaAutosize from '@mui/material/TextareaAutosize'
// import { compose } from '@meltwater/phi'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { getDroppedOrSelectedFiles } from 'html5-file-selector'
import { Message } from '../styles/upload'
import { ImageContainer, TextContainer } from '../styles/experience'
import { useExperience } from '../hooks/useExperience'
// import { wrap } from 'popmotion'
// import { useInput } from '../hooks/use-input'
import { InputAdornment } from '@mui/material'
import { getDatabase, ref as dbRef, onValue } from 'firebase/database'
import { useNavigate, useParams } from 'react-router-dom'

const darkTheme = createTheme({
  palette: {
    mode: 'dark'
  }
})

export const EditExperience = () => {
  const { id } = useParams()

  const {
    actions: { experienceUpdate }
  } = useExperience()

  const [loading, setLoading] = useState(false)
  const [files, setFiles] = useState([])
  const [experience, setExperience] = useState({
    location: '',
    date: '',
    price: '',
    description: '',
    endDate: ''
  })
  const navigate = useNavigate()

  const [images, setImages] = useState([])

  const fetchExperiences = () => {
    const db = getDatabase()
    onValue(
      dbRef(db, `experiences/${id}`),
      snapshot => {
        setExperience(snapshot.val())
        setImages(snapshot.val().images)
        setLoading(false)
      },
      { onlyOnce: true }
    )
  }

  useEffect(() => {
    fetchExperiences()
    // console.log(experience.date)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // const imageIndex = wrap(0, images.length)

  const handleChange = e => {
    setExperience({ ...experience, [e.target.name]: e.target.value })
  }

  const handleSubmitExperience = async e => {
    try {
      e.preventDefault()
      const payload = { ...experience, files, id }

      setLoading(true)
      await experienceUpdate(payload)
      setLoading(false)
      navigate('/admin/experiences')
      setFiles([])
      setExperience({})
      window.alert('Experience edited succefully')
    } catch (error) {
      window.alert('An error occurred while creating experience')
      setLoading(false)
    }
  }

  const fileParams = ({ meta }) => {
    return { url: 'https://httpbin.org/post' }
  }
  // const onSubmit = (files, allFiles) => {
  //   setFiles(allFiles)
  // }
  const getFilesFromEvent = e => {
    return new Promise(resolve => {
      getDroppedOrSelectedFiles(e).then(chosenFiles => {
        resolve(chosenFiles.map(f => f.fileObject))
      })
    })
  }
  const selectFileInput = ({ accept, onFiles, files, getFilesFromEvent }) => {
    // const textMsg =
    //   files.length > 0
    //     ? 'Drag or Click to upload more images'
    //     : 'Drag files or click to add photo of experiences'
    return (
      <>
        <Message> {loading && 'UPLOADING IMAGES'}</Message>
        <img src={images} alt='experience' style={{ width: '50%' }} />
        <Message>N.B: You cannot edit or upload more images</Message>
        {/* <UploadContainer>
          <Message> {textMsg}</Message>
          <input
            style={{ display: 'none' }}
            type='file'
            accept={accept}
            multiple
            onChange={e => {
              getFilesFromEvent(e).then(chosenFiles => {
                onFiles(chosenFiles)
              })
            }}
            defaultValue={images[imageIndex]}
          />
        </UploadContainer> */}
      </>
    )
  }

  const renderLoader = () => {
    if (loading) {
      return <CircularProgress />
    }
    return (
      <Button
        type='submit'
        fullWidth
        variant='contained'
        sx={{ mt: 3, mb: 2, fontFamily: 'Fira Sans Condensed' }}
      >
        Update Experience
      </Button>
    )
  }
  return (
    <ThemeProvider theme={darkTheme}>
      <ImageContainer>
        <Dropzone
          // onSubmit={onSubmit}
          InputComponent={selectFileInput}
          getFilesFromEvent={getFilesFromEvent}
          getUploadParams={fileParams}
          accept='pdf/*'
          inputContent='Drop A File'
          styles={{
            dropzone: {
              width: '30vw',
              height: '40vh',
              marginTop: '10vh',
              color: 'white'
            },
            dropzoneActive: { borderColor: 'green' }
          }}
        />
      </ImageContainer>
      <TextContainer>
        <Container component='main' maxWidth='xs'>
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Typography
              component='h1'
              variant='h5'
              sx={{ fontFamily: 'Fira Sans Condensed' }}
            >
              Create Experience
            </Typography>
            <Box
              component='form'
              noValidate
              sx={{ mt: 2 }}
              onSubmit={handleSubmitExperience}
            >
              <TextField
                sx={{ fontFamily: 'Fira Sans Condensed' }}
                margin='normal'
                required
                fullWidth
                id='location'
                label='Location'
                name='location'
                value={experience.location}
                onChange={handleChange}
                autoFocus
                // defaultValue={experience.location}
                InputLabelProps={{
                  shrink: true
                }}
              />
              <TextField
                margin='normal'
                id='date'
                label='Start Date'
                type='date'
                name='date'
                onChange={handleChange}
                value={experience.date}
                sx={{ width: 220 }}
                InputLabelProps={{
                  shrink: true
                }}
              />

              <TextField
                id='dateEnd'
                margin='normal'
                label='End Date'
                type='date'
                name='endDate'
                onChange={handleChange}
                value={experience.endDate}
                sx={{ width: 220 }}
                InputLabelProps={{
                  shrink: true
                }}
              />
              <TextField
                sx={{ fontFamily: 'Fira Sans Condensed' }}
                margin='normal'
                required
                fullWidth
                name='price'
                id='outlined-number'
                label='Price'
                type='number'
                placeholder='0'
                onChange={handleChange}
                value={experience.price}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>$</InputAdornment>
                  )
                }}
              />
              <TextareaAutosize
                sx={{ fontFamily: 'Fira Sans Condensed' }}
                required
                fullwidth
                name='description'
                placeholder='Description'
                style={{ width: '20vw' }}
                id='description'
                value={experience.description}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true
                }}
              />
              {renderLoader()}
            </Box>
          </Box>
        </Container>
      </TextContainer>
    </ThemeProvider>
  )
}
