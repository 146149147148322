import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { getDatabase, ref as dbRef, onValue } from 'firebase/database'
import { Link } from 'react-router-dom'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'
import { transform } from '../utils/index'
import {
  ExperiencesContainer,
  ExpImage
  // ExpDetails
} from '../styles/experiences'
// import { BookNow } from '../styles/home'

export const Experiences = () => {
  // const [loadingE, setLoadingE] = useState(true)
  const [trips, setTrips] = useState([])

  const fetchExperiences = () => {
    const db = getDatabase()
    onValue(
      dbRef(db, 'experiences/'),
      snapshot => {
        setTrips(transform(snapshot.val()).reverse())
        // setLoadingE(false)
      },
      { onlyOnce: true }
    )
    // setLoadingE(false)
  }
  useEffect(() => {
    fetchExperiences()
  }, [])
  const renderExperiencesImages = () => {
    return trips.map((trip, i) => (
      <Link
        key={i}
        style={{ textDecoration: 'none' }}
        to={`/experiences/${trip.id}`}
      >
        <ExpImage
          whileHover={{
            opacity: 0.4,
            transition: { duration: 0.2 }
          }}
          key={i}
          src={trip.images[0]}
          style={{ width: '100%', display: 'block', padding: '8px' }}
          alt=''
        />
        <h2
          style={{
            textDecoration: 'none',
            color: 'white',
            fontFamily: 'Fira Sans Condensed '
          }}
        >
          {trip.location}
        </h2>
      </Link>
    ))
  }

  return (
    <ExperiencesContainer>
      <div
        style={{
          color: 'white',
          textAlign: 'center',
          textTransform: 'uppercase',
          fontWeight: 'bold',
          fontFamily: 'Fira Sans Condensed',
          fontSize: '20px'
        }}
      >
        Experiences
      </div>
      <Helmet>
        <title>Experiences</title>
        <meta name='Experiences' content='All Experiences' />
      </Helmet>
      <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
        <Masonry>{renderExperiencesImages()}</Masonry>
      </ResponsiveMasonry>
    </ExperiencesContainer>
  )
}
