import React, { useState, useEffect } from 'react'
import { getDatabase, ref as dbRef, onValue, update } from 'firebase/database'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import TextareaAutosize from '@mui/material/TextareaAutosize'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'

export const Frontpage = () => {
  const [meta, setMeta] = useState({})
  const [loading, setLoading] = useState(false)
  const handleChange = e =>
    setMeta({ ...meta, [e.target.name]: e.target.value })
  const handleSubmit = e => {
    setLoading(true)
    e.preventDefault()
    const db = getDatabase()
    update(dbRef(db, 'home/metadata/'), {
      ...meta
    })
      .then(() => {
        window.alert('Front page updated')
        setLoading(false)
      })
      .catch(err => {
        window.alert('An error has occurred')
        throw new Error(err)
      })
  }
  const fetchHomeMeta = () => {
    const db = getDatabase()
    onValue(
      dbRef(db, 'home/metadata/'),
      snapshot => {
        setMeta(snapshot.val())
      },
      { onlyOnce: true }
    )
  }
  const renderLoader = () => {
    if (loading) {
      return <CircularProgress />
    }
    return (
      <Button
        type='submit'
        variant='contained'
        fullWidth
        size='large'
        sx={{
          mt: 3,
          mb: 2,
          fontFamily: 'Fira Sans Condensed',
          width: '40%',
          mr: 'auto',
          ml: 'auto'
        }}
      >
        Update
      </Button>
    )
  }
  useEffect(() => {
    fetchHomeMeta()
  }, [])
  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Typography
          component='h1'
          variant='h5'
          sx={{ fontFamily: 'Fira Sans Condensed' }}
        >
          Update Frontpage
        </Typography>
        <Box component='form' noValidate sx={{ mt: 2 }} onSubmit={handleSubmit}>
          <TextareaAutosize
            sx={{ fontFamily: 'Fira Sans Condensed' }}
            margin='normal'
            fullwidth
            style={{ width: '20vw' }}
            id='Front page message'
            description='Front page message'
            value={meta.frontText}
            name='frontText'
            InputLabelProps={{
              shrink: true
            }}
            onChange={handleChange}
            autoFocus
          />
          <TextField
            sx={{ fontFamily: 'Fira Sans Condensed', width: '50vw' }}
            margin='normal'
            required
            fullWidth
            value={meta.frontImgUrl}
            id='Front page image url'
            label='Front page image url'
            InputLabelProps={{
              shrink: true
            }}
            multiline
            name='frontImgUrl'
            onChange={handleChange}
            autoFocus
          />
          <TextField
            sx={{ fontFamily: 'Fira Sans Condensed', width: '50vw' }}
            margin='normal'
            required
            fullWidth
            value={meta.ytLink}
            id='Front page video url'
            InputLabelProps={{
              shrink: true
            }}
            label='Front page video url'
            multiline
            name='ytLink'
            onChange={handleChange}
            autoFocus
          />
          {renderLoader()}
        </Box>
      </Box>
    </Container>
  )
}
