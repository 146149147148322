import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React from 'react'
import countries from 'i18n-iso-countries'
import enLocale from 'i18n-iso-countries/langs/en.json'
import itLocale from 'i18n-iso-countries/langs/it.json'

const CountrySelector = ({ selectedCountry, onChanged }) => {
  const changeHandler = value => {
    onChanged(value)
  }
  countries.registerLocale(enLocale)
  countries.registerLocale(itLocale)

  const countryObj = countries.getNames('en', { select: 'official' })

  const countryArr = Object.entries(countryObj).map(([key, value]) => {
    return {
      label: value,
      value: key
    }
  })

  return (
    <>
      <FormControl
        fullWidth
        margin='normal'
        sx={{ fontFamily: 'Fira Sans Condensed' }}
      >
        <InputLabel>Select Country</InputLabel>
        <Select
          label='Select Country'
          value={selectedCountry}
          onChange={e => changeHandler(e.target.value)}
          required
        >
          {!!countryArr?.length &&
            countryArr.map(({ label, value }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </>
  )
}

export default CountrySelector
