import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 70%;
  margin: 25px auto;
  border-radius: 15px;
`

export const ProfileWrapper = styled.div`
  padding: 25px;
  align-self: center;
`

export const TextContainer = styled.div`
  align-self: center;
  padding-top: 2vh;
  margin: 0 auto;
  /* color: white; */
  div {
    padding: 7px;
    width: 70%;
    margin: 0 auto;
    @media (max-width: 768px) {
      width: 90%;
    }
  }
  img {
    width: 100%;
    max-width: 250px;
  }
`
