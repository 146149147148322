import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail
} from 'firebase/auth'
import { map as mapAwait } from 'awaiting'
import { v4 as uuidv4 } from 'uuid'
import {
  getStorage,
  uploadBytes,
  getDownloadURL,
  ref as stRef
} from 'firebase/storage'
import { getDatabase, ref, set, get } from 'firebase/database'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const initialState = {
  user: {},
  errorText: '',
  error: false
}

const createAuthErrors = code => {
  if (code === 'auth/invalid-email') {
    throw new Error('Email is invalid')
  }
  if (code === 'auth/missing-email') {
    throw new Error('No email provided')
  }
  if (code === 'auth/user-not-found') {
    throw new Error('user not found')
  }
  if (code === 'auth/wrong-password') {
    throw new Error('Email or password is incorrect')
  }
  if (code === 'auth/weak-password') {
    throw new Error('Password is weak, should be atleast 6 characters')
  }
  if (code === 'auth/email-already-in-use') {
    throw new Error('Email already in use')
  }
  if (code === 'auth/invalid-email') {
    throw new Error('Email is invalid')
  }
  throw new Error('An error occured please try again')
}
const createFileUploads = async (files, uploader, id) => {
  try {
    if (files < 1) {
      window.alert(
        'Please add an image and click sumbit to add atleast one image'
      )
      return
    }
    const res = await mapAwait(files, files.length, uploader(id))
    return res
  } catch (e) {
    throw new Error(e)
  }
}

const uploadImage = id => async file => {
  const storage = getStorage()
  const imageId = uuidv4()
  console.log(file)
  const storageRef = stRef(storage, `users/${id}/doc/${imageId}.jpg`)
  try {
    const metadata = {
      contentType: 'image/jpeg'
    }
    const data = file
    const snapshot = await uploadBytes(storageRef, data, metadata)
    const url = await getDownloadURL(snapshot.ref)
    console.log(url)
    return url
  } catch (e) {
    throw new Error(e)
  }
}

const userCreate = createAsyncThunk(
  '/signup',
  async (payload, { rejectWithValue }) => {
    try {
      const auth = getAuth()
      const db = getDatabase()
      const res = await createUserWithEmailAndPassword(
        auth,
        payload.email,
        payload.password
      )
      const { uid } = res.user
      const covidFiles = await createFileUploads(payload.covidFiles, uploadImage, uid)
      const passFiles = await createFileUploads(payload.passFiles, uploadImage, uid)
      await set(ref(db, 'users/' + uid), {
        firstName: payload.firstName,
        lastName: payload.lastName,
        email: payload.email,
        profile_picture: '',
        country: payload.country,
        covid: payload.covid,
        passportExp: payload.passportExp,
        passportIssue: payload.passportIssue,
        passportNum: payload.passportNum,
        mobileNumber: payload.mobileNumber,
        travel: payload.travel,
        emerg: payload.emerg,
        where: payload.where,
        concern: payload.concern,
        excite: payload.excite,
        covidFiles,
        passFiles
      })
      return { ...res }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

const userLogin = createAsyncThunk(
  '/login',
  async (payload, { rejectWithValue }) => {
    try {
      const auth = getAuth()
      const db = getDatabase()
      console.log(payload)
      const res = await signInWithEmailAndPassword(
        auth,
        payload.email,
        payload.password
      )
      const { uid } = res.user
      const userRef = ref(db, 'users/' + uid)
      const snapshot = await get(userRef)
      return { ...snapshot.val(), uid }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

const userReset = createAsyncThunk(
  '/rest',
  async (payload, { rejectWithValue }) => {
    try {
      const auth = getAuth()
      await sendPasswordResetEmail(auth, payload.email)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

const userLogout = createAsyncThunk('/logout', async ({ payload }) => {
  try {
    const auth = getAuth()
    const res = await signOut(auth)
    window.localStorage.removeItem('user')
    return { ...res }
  } catch (error) {}
})

const userCheck = createAsyncThunk('/user', user => {
  return {
    ...user
  }
})
const userLoginSlice = createSlice({
  name: 'auth',
  initialState,
  extraReducers: builder => {
    builder.addCase(userLogin.fulfilled, (state, action) => {
      return {
        ...state,
        user: action.payload
      }
    })
    builder.addCase(userCheck.fulfilled, (state, action) => {
      console.log(action.payload)
      return {
        ...state,
        user: action.payload
      }
    })
    builder.addCase(userLogin.rejected, (state, action) => {
      createAuthErrors(action.payload.code)
    })
    builder.addCase(userLogout.fulfilled, (state, action) => {
      return {
        ...state,
        user: action.payload
      }
    })
    builder.addCase(userCreate.fulfilled, (state, action) => {
      return {
        ...state,
        user: action.payload
      }
    })
    builder.addCase(userReset.fulfilled, (state, action) => {
      return {
        ...state
      }
    })
    builder.addCase(userCreate.rejected, (state, action) => {
      createAuthErrors(action.payload.code)
    })
    builder.addCase(userReset.rejected, (state, action) => {
      createAuthErrors(action.payload.code)
    })
  }
})

export { userLoginSlice, userLogin, userCreate, userLogout, userCheck, userReset }
