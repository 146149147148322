import { motion } from 'framer-motion'
import styled from 'styled-components'

export const TripMetaContainer = styled.div`
  /* background-color: green; */
  font-family: 'Fira Sans', sans-serif;
  position: absolute;
  margin-top: -10vh;
  width: 45vw;
  /* height: 70vh; */
  line-height: 20pt;
  top: 25vh;
  left: 50vw;
  @media (max-width: 768px) {
    width: 100vw;
    left: 0vw;
    top: 90vh;
    margin-top: 2vh;
  }
  @media (min-width: 768px) (max-width: 868px) {
    top: 25vh;
  }
`
export const DesContainer = styled.div`
  /* background-color: green; */
  width: 45vw;
  /* position: absolute;
  margin-top: -10vh;
  height: 70vh;
  left: 50vw; */
  @media (max-width: 768px) {
    width: 100vw;
    padding: 20px;
  }
`
export const Location = styled.div`
  font-family: 'Fira Sans', sans-serif;
  /* width: 20vw; */
  /* background-color: red; */
`
export const Heading = styled.h1`
  color: white;
  /* border-style: solid; */
  font-weight: 100;
  display: inline;
  font-family: 'Fira Sans', sans-serif;
  font-size: 4vh;
  @media (max-width: 768px) {
    padding: 20px;
  }
  @media (max-width: 868px) {
    font-size: 3vh;
  }
`
export const Price = styled.h1`
  color: white;
  font-weight: 600;
  font-family: 'Fira Sans', serif;
  font-size: 1.7vh;
  margin: 0;
  display: inline;
  @media (max-width: 768px) {
    width: 100vw;
    margin-left: 20px;
    display: inline;
    font-size: 3vh;
  }
`
export const Date = styled.h1`
  font-weight: 600;
  font-weight: 100;
  font-family: 'Fira Sans', sans-serif;
  font-size: 1.3vh;
  font-size: 2vh;
  width: 20vw;
  /* background-color: white; */
  color: white;
  display: block;
  @media (max-width: 768px) {
    padding: 20px;
    width: 70vw;
    font-size: 2vh;
  }
`

export const Description = styled.p`
  font-weight: 100;
  font-family: 'Fira Sans', sans-serif;
  font-size: 2vh;
  line-height: 28pt;
  /* background-color: white; */
  color: white;
  white-space: pre-line;
  /* display: block; */
  @media (max-width: 868px) {
    font-size: 2vh;
  }
`
export const Pay = styled(motion.button)`
  & {
    font-size: 1.5vh;
    font-weight: 600;
    letter-spacing: 1px;
    padding: 5px;
    outline: 0;
    color: black;
    cursor: pointer;
    float: right;
    font-family: 'Fira Sans', serif;
    border: none;
    display: inline;
    background-color: white;
  }
  @media (max-width: 768px) {
    margin-right: 30px;
    font-size: 2.5vh;
  }
`
export const Pay2 = styled(motion.button)`
  & {
    font-size: 1.5vh;
    font-weight: 600;
    letter-spacing: 1px;
    padding: 5px;
    outline: 0;
    color: black;
    cursor: pointer;
    float: left;
    font-family: 'Fira Sans', serif;
    border: none;
    display: inline;
    background-color: white;
  }
  @media (max-width: 768px) {
    margin-right: 30px;
    font-size: 2.5vh;
  }
`
