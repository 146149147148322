import React, { useEffect, useState } from 'react'
import { getDatabase, ref as dbRef, onValue } from 'firebase/database'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import IconButton from '@mui/material/IconButton'
import TableContainer from '@mui/material/TableContainer'
import ClearIcon from '@mui/icons-material/Clear'
import TextField from '@mui/material/TextField'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { transform } from '../utils/index'
import { Link } from 'react-router-dom'

const createData = (email, firstName, lastName, id, mobile, files) => {
  return { email, firstName, lastName, id, mobile, files }
}

const User = () => {
  const [users, setUsers] = useState([])
  const [, setLoading] = useState(true)
  const [data, setData] = useState([])
  const [searched, setSearched] = useState('')
  const fetchUsers = () => {
    const db = getDatabase()
    onValue(
      dbRef(db, 'users/'),
      snapshot => {
        setUsers(transform(snapshot.val()))
        setData(transform(snapshot.val()))
        setLoading(false)
      },
      { onlyOnce: true }
    )
    setLoading(false)
  }
  useEffect(() => {
    fetchUsers()
    // console.log(users)
  }, [])
  const rows = data.map(user =>
    createData(
      user.email,
      user.firstName,
      user.lastName,
      user.id,
      user.mobileNumber
    )
  )
  const requestSearch = e => {
    setSearched(e.target.value)
    const filteredUsers = users.filter(user =>
      user.email.toLowerCase().includes(searched.toLowerCase())
    )
    setData(filteredUsers)
  }

  const cancelSearch = () => {
    setSearched('')
    setData(users)
  }

  return (
    <>
      <Paper>
        <TextField
          value={searched}
          id='search'
          label='Search by email'
          autoComplete='off'
          name='search'
          type='text'
          sx={{ color: 'black' }}
          onChange={e => requestSearch(e)}
        />
        <IconButton onClick={cancelSearch}>
          <ClearIcon />
        </IconButton>
      </Paper>
      <TableContainer sx={{ padding: '20px' }} component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell align='right'>First Name</TableCell>
              <TableCell align='right'>Last Name</TableCell>
              <TableCell align='right'>id</TableCell>
              <TableCell align='right'>Mobile</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow
                key={row.email}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component='th' scope='row'>
                  <Link to={`${row.id}`}>{row.email}</Link>
                </TableCell>
                <TableCell align='right'>{row.firstName}</TableCell>
                <TableCell align='right'>{row.lastName}</TableCell>
                <TableCell align='right'>{row.id}</TableCell>
                <TableCell align='right'>{row.mobile}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default User
