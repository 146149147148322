import styled from 'styled-components'
import { motion } from 'framer-motion'

export const BannerContainer = styled.div`
  border-style: solid;
  /* background-color: white; */
  float: left;
  width: 40vw;
  /* position: fixed; */
  /* height: 90vh; */
  @media (max-width: 768px) {
    width: 100vw;
    position: relative;
    height: auto;
  }
`
export const UpcomingTripsContainer = styled.div`
  border-style: solid;
  position: absolute;
  left: 40vw;
  /* background-color: red; */
  float: left;
  width: 35vw;
  /* display: inline; */
  /* height: 90vh; */
  @media (max-width: 768px) {
    position: relative;
    width: 100vw;
    left: 0;
  }
`
export const BannerImage = styled(motion.img)`
  border-style: solid;
  width: 100%;
  /* background-repeat: no-repeat; */
  /* background-size: 300px 100px; */
  /* margin-top: 10vh; */
  max-width: 40vw;
  /* max-height: 80vh; */
  background-image: url(${props => props.url});
  @media (max-width: 768px) {
    max-height: 80vh;
    max-width: 100vw;
  }
`
export const BannerTextContainer = styled.div`
  background-color: #010203;
  border-style: solid;
  width: 100%;
  height: 20%;
`
export const BannerText = styled.h2`
  color: white;
  /* font-weight: 100; */
  font-family: 'Sorts Mill Goudy', serif;
  font-size: 20px;
  width: 100%;
  padding: 60px;
  white-space: pre-line;
  line-height: 25pt;
  padding: 20px;
  a {
    text-decoration: none;
  }
  img {
    padding-top: 10px;
    width: 5%;
  }
`
export const WatchVideo = styled(motion.button)`
  & {
    font-size: 18px;
    font-weight: 300;
    padding: 13px 50px 13px;
    outline: 0;
    color: white;
    font-family: inherit;
    border: none;
    margin-left: -18px;
    cursor: pointer;
    position: relative;
    background-color: black;
  }
  &::after {
    background-color: #ffe54c;
    width: 100%;
    z-index: -1;
    position: absolute;
    height: 100%;
    text-align: left;
    top: 7px;
    left: 7px;
    transition: 0.2s;
  }
  &:hover::after {
    top: 0px;
    left: 0px;
  }
`

export const Anlo = styled.span`
  text-transform: uppercase;
`
export const VideoContainer = styled(motion.div)`
  border-style: solid;
  width: 100%;
  height: 80vh;
`
export const UpcomingTrips = styled.h1`
  color: white;
  font-weight: 300;
  font-family: 'Roboto', sans-serif;
  font-size: 2.5vh;
  width: 1%;
  border: none;
  border-color: white;
`
export const TripContainer = styled.div`
  /* background-color: green; */
  margin-top: 2px;
  width: 35vw;
  height: auto;
  @media (max-width: 768px) {
    margin-top: 10px;
    width: 100vw;
  }
`
export const Trip = styled(motion.div)`
  /* background-color: pink; */
  /* margin-right: 10vw; */
  position: relative;
  font-family: 'Roboto', sans-serif;
  width: 30vw;
  height: auto;
  background-image: url(${props => props.url});
  & h1 {
    color: white;
    font-family: 'Sorts Mill Goudy', serif;
    font-size: 4vh;
  }
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0);
  }
  &:hover::before {
    background-color: rgba(0, 0, 0, 0.5);
  }
  & img {
    display: block;
  }
  & h2 {
    position: absolute;
    top: 60%;
    left: 50%;
    font-size: 1.3vh;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    opacity: 0;
  }
  &:hover h2 {
    opacity: 1;
  }
  @media (max-width: 768px) {
    width: 100vw;
    & h1 {
      padding-left: 20px;
    }
  }
`

export const TripImage = styled(motion.img)`
  /* background-color: pink; */
  float: left;
  width: inherit;
  max-width: 100%;
  max-height: 50%;
  @media (max-width: 768px) {
    width: 98vw;
    padding-top: 20px;
    max-width: 98vw;
    max-height: 50%;
    margin-left: 0.8vw;
  }
`
export const BookNow = styled(motion.h2)`
  position: absolute;
  width: 9vw;
  left: 50%;
  top: 50%;
  margin-top: 30vh;
  font-weight: 700;
  font-size: 30px;
  text-align: center;
  text-transform: uppercase;
  color: white;
  z-index: 1;
  transition: top 0.5s ease;
  transform: translate(-50%, -50%);
  transition: opacity 0.35s ease;
`
