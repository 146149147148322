import styled from 'styled-components'
import { motion } from 'framer-motion'

export const ExperiencesContainer = styled.div`
  /* border-style: solid;
    border-color: white; */
  width: 96vw;
  margin-left: 2vw;
  /* height: 100vh; */
`
export const ExpImage = styled(motion.img)``

export const ExpDetails = styled(motion.h2)`
  position: absolute;
  width: 9vw;
  left: 50%;
  top: 50%;
  font-weight: 700;
  font-size: 30px;
  text-align: center;
  text-transform: uppercase;
  color: white;
  z-index: 1;
  opacity: 0;
  transition: top 0.5s ease;
  transform: translate(-50%, -50%);
  transition: opacity 0.35s ease;
  &:hover {
    opacity: 1;
  }
`
