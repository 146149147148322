import React from 'react'
import { Link } from 'react-router-dom'
import { Container, Home } from '../styles/order'

const Success = () => {
  return (
    <Container>
      <h1>Thank you for your order.</h1>
      <Link to='/' style={{ textDecoration: 'none' }}>
        <Home
          whileHover={{
            opacity: 0.4,
            transition: { duration: 0.2 }
          }}
        >
          Back to home
        </Home>
      </Link>
    </Container>
  )
}

export default Success
