import React, { useState } from 'react'
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import axios from 'axios'
import { map as mapAwait } from 'awaiting'
import { getDroppedOrSelectedFiles } from 'html5-file-selector'
import { UploadContainer, Message } from '../styles/upload'
import { toBase64 } from '../utils'

const createFileUploads = async (files, uploader) => {
  return mapAwait(files, files.length, uploader)
}

const uploadImage = async file => {
  try {
    const data = await toBase64(file.file)
    const payload = {
      file: data
    }
    const config = {
      headers: {
        'x-api-key': process.env.REACT_APP_FILE_API_KEY
      }
    }
    const res = await axios.post(
      `${process.env.REACT_APP_FILE_SERVICE_URL}/prod/image-upload`,
      payload,
      config
    )
    return res
  } catch (e) {
    return e
  }
}

export const Upload = () => {
  const [loading, setLoading] = useState(false)
  const fileParams = ({ meta }) => {
    return { url: 'https://httpbin.org/post' }
  }
  const onFileChange = ({ meta, file }, status) => {
    console.log(status, meta, file)
  }
  const onSubmit = (files, allFiles) => {
    if (loading) {
      return
    }
    setLoading(true)
    return createFileUploads(allFiles, uploadImage)
      .then(res => {
        setLoading(false)
        window.alert('Files uploaded succefully')
      })
      .then(() => {
        allFiles.forEach(f => f.remove())
      })
      .catch(err => {
        setLoading(false)
        window.alert('An error occured while uploading files')
        throw err
      })
  }
  const getFilesFromEvent = e => {
    return new Promise(resolve => {
      getDroppedOrSelectedFiles(e).then(chosenFiles => {
        resolve(chosenFiles.map(f => f.fileObject))
      })
    })
  }
  const selectFileInput = ({ accept, onFiles, files, getFilesFromEvent }) => {
    const textMsg =
      files.length > 0
        ? 'Drag or Click to upload more images'
        : 'DRAG FILES OR CLICK TO BROWSE'
    return (
      <>
        <Message> {loading && 'UPLOADING IMAGES'}</Message>
        <UploadContainer>
          <Message> {textMsg}</Message>
          <input
            style={{ display: 'none' }}
            type='file'
            accept={accept}
            multiple
            onChange={e => {
              getFilesFromEvent(e).then(chosenFiles => {
                onFiles(chosenFiles)
              })
            }}
          />
        </UploadContainer>
      </>
    )
  }
  return (
    <Dropzone
      onSubmit={onSubmit}
      onChangeStatus={onFileChange}
      InputComponent={selectFileInput}
      getFilesFromEvent={getFilesFromEvent}
      getUploadParams={fileParams}
      accept='image/*'
      inputContent='Drop A File'
      styles={{
        dropzone: {
          width: '70vw',
          height: '60vh',
          color: 'white',
          marginTop: '10vh'
        },
        dropzoneActive: { borderColor: 'green' }
      }}
    />
  )
}
