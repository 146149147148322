import { getStorage, uploadBytes, getDownloadURL, ref } from 'firebase/storage'
import { getDatabase, ref as dbRef, set, update } from 'firebase/database'
import { v4 as uuidv4 } from 'uuid'
import { map as mapAwait } from 'awaiting'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const initialState = {
  experiences: [],
  experience: {}
}

// const transform = (data) => Object.entries(data).map(([id, props]) => ({ ...props, id }))

const createFileUploads = async (files, uploader) => {
  try {
    if (files < 1) {
      window.alert(
        'Please add an image and click sumbit to add atleast one image'
      )
      return
    }
    const tripId = uuidv4()
    const res = await mapAwait(files, files.length, uploader(tripId))
    return res
  } catch (e) {
    throw new Error(e)
  }
}

const uploadImage = id => async file => {
  const storage = getStorage()
  const imageId = uuidv4()
  const storageRef = ref(storage, `experiences/${id}/${imageId}.jpg`)
  try {
    const metadata = {
      contentType: 'image/jpeg'
    }
    const data = file.file
    const snapshot = await uploadBytes(storageRef, data, metadata)
    const url = await getDownloadURL(snapshot.ref)
    console.log(url)
    return url
  } catch (e) {
    throw new Error(e)
  }
}

const creatExperience = createAsyncThunk(
  '/experienceCreate',
  async (payload, { rejectWithValue }) => {
    try {
      const res = await createFileUploads(payload.files, uploadImage)
      const db = getDatabase()
      const id = uuidv4()
      const dbRes = await set(dbRef(db, `experiences/${id}`), {
        location: payload.location,
        price: payload.price,
        description: payload.description,
        images: res,
        date: payload.date,
        endDate: payload.endDate
      })

      return { ...dbRes }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

const updateExperience = createAsyncThunk(
  '/experienceUpdate',
  async (payload, { rejectWithValue }) => {
    try {
      const db = getDatabase()
      const id = payload.id
      const dbRes = await update(dbRef(db, `experiences/${id}`), {
        location: payload.location,
        price: payload.price,
        description: payload.description,
        date: payload.date,
        endDate: payload.endDate,
        images: payload.images
      })

      return { ...dbRes }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

const creatExperienceSlice = createSlice({
  name: 'experience',
  initialState,
  extraReducers: builder => {
    builder.addCase(creatExperience.fulfilled, (state, action) => {
      return {
        ...state,
        experience: action.payload
      }
    })
    builder.addCase(creatExperience.rejected, (state, action) => {
      throw new Error(action.payload)
    })
  }
})

const updateExperienceSlice = createSlice({
  name: 'updateExperience',
  initialState,
  extraReducers: builder => {
    builder.addCase(updateExperience.fulfilled, (state, action) => {
      return {
        ...state,
        updateExperience: action.payload
      }
    })
    builder.addCase(updateExperience.rejected, (state, action) => {
      throw new Error(action.payload)
    })
  }
})

export {
  creatExperienceSlice,
  creatExperience,
  updateExperienceSlice,
  updateExperience
}
