import { useDispatch, useSelector } from 'react-redux'
import { creatExperience, updateExperience } from '../reducers/experience'

export const useExperience = () => {
  const dispatch = useDispatch()
  const experienceCreate = payload => dispatch(creatExperience(payload))
  const experienceUpdate = payload => dispatch(updateExperience(payload))
  const experience = useSelector(state => state.experience)
  const update = useSelector(state => state.updateExperience)
  return {
    state: { experience, update },
    actions: {
      experienceCreate,
      experienceUpdate
    }
  }
}
