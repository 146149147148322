import styled from 'styled-components'

export const UploadContainer = styled.label`
  background-color: 'red';
  border: 1px solid;
  text-align: center;
`
export const Message = styled.h2`
  font-family: 'Fira Sans Condensed', sans-serif;
  font-weight: 700;
  font-size: 2vh;
  text-align: center;
  text-transform: uppercase;
`
