import { useDispatch, useSelector } from 'react-redux'
import { userLogin, userCreate, userLogout, userCheck, userReset } from '../reducers/auth'

export const useAuth = () => {
  const dispatch = useDispatch()
  const loginUser = payload => dispatch(userLogin(payload))
  const logoutUser = () => dispatch(userLogout({}))
  const createUser = payload => dispatch(userCreate(payload))
  const currentUser = user => dispatch(userCheck(user))
  const resetUser = payload => dispatch(userReset(payload))
  const user = useSelector(state => state.user)

  return {
    state: {
      user
    },
    actions: {
      loginUser,
      logoutUser,
      createUser,
      currentUser,
      resetUser
    }
  }
}
