import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import CircularProgress from '@mui/material/CircularProgress'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

export const AlertDialog = ({ toggle, setToggle, handlePayment, loading, handleInstallPayment }) => {
  const handleClose = () => {
    setToggle(false)
  }

  const renderLoading = () => {
    if (!loading) {
      return (
        <>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handlePayment} autoFocus>
            Pay in Full
          </Button>
          <Button onClick={handleInstallPayment} autoFocus>
            Pay in installments
          </Button>
        </>
      )
    } return <CircularProgress />
  }

  return (
    <div>
      <Dialog
        open={toggle}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          Do you agree to terms and policies?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            By Clicking any of payment options and making payment you agree to all terms and
            policies stated{' '}
            <a href='https://firebasestorage.googleapis.com/v0/b/anlotravel-2e6dc.appspot.com/o/policy%2FAnlo%20Travel%20Policy.pdf?alt=media&token=2170138c-9ff0-49a1-a412-f8a9d96e6b8b'>
              here
            </a>
          </DialogContentText>
        </DialogContent>
        <DialogActions>{renderLoading()}</DialogActions>
      </Dialog>
    </div>
  )
}
