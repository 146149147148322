import { motion } from 'framer-motion'
import styled from 'styled-components'

export const Container = styled.div`
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 90vh;
  font-family: 'Fira Sans Condensed';
  flex-direction: column;
`
export const Home = styled(motion.h3)`
  color: white;
`
