import React, { useState } from 'react'
import { BurgerIconContainer, Layer } from '../styles/dashboard'
import { DashMenu } from './DashMenu'

export const Dashboard = () => {
  const [open, setOpen] = useState(false)
  return (
    <>
      <BurgerIconContainer open={open} onClick={() => setOpen(!open)}>
        <Layer />
        <Layer />
        <Layer />
      </BurgerIconContainer>
      <DashMenu open={open} />
    </>
  )
}
