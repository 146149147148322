import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import ReactDOM from 'react-dom'
import { initializeApp } from 'firebase/app'
import { Provider } from 'react-redux'
import 'normalize.css'
import './index.css'
import App from './App'
import { store } from './store'
import reportWebVitals from './reportWebVitals'

const firebaseConfig = {
  apiKey: 'AIzaSyCbPYiiXDfl5pNe8dZ7LTu76r_M-I078rk',
  authDomain: 'anlotravel-2e6dc.firebaseapp.com',
  databaseURL: 'https://anlotravel-2e6dc-default-rtdb.firebaseio.com',
  projectId: 'anlotravel-2e6dc',
  storageBucket: 'anlotravel-2e6dc.appspot.com',
  messagingSenderId: '296309899059',
  appId: '1:296309899059:web:07ea1f83f063f35d3e4846',
  measurementId: 'G-CWXNF63S1N'
}

initializeApp(firebaseConfig)
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
