import styled from 'styled-components'

export const Container = styled.div``

export const ProfileWrapper = styled.div`
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const TextContainer = styled.div`
  padding-top: 2vh;
  margin: 0 auto;
  color: white;
  text-align: center;
`
